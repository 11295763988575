import {
	configureStore,
	getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { combineReducers, compose } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import ReduxThunk from 'redux-thunk';
import agentSlice from './reducers/agentSlice';
import graphSlice from './reducers/graphSlice';
import UserSlice from './reducers/usersSlice';
import offerSlice from './reducers/offerSlice';
import dataSlice from './reducers/dataSlice';

const persistConfig = {
	key: 'root',
	storage: storage,
	whitelist: ['graph'],
	blacklist: [],
	transforms: [],
};

const reducers = combineReducers({
	graph: graphSlice,
	user: UserSlice,
	agent: agentSlice,
	offer: offerSlice,
	data: dataSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const middleware = getDefaultMiddleware({
	serializableCheck: false,
	immutableCheck: false,
}).concat(ReduxThunk);

let enhancedCompose = compose;

export const store = configureStore({
	reducer: persistedReducer,
	middleware: enhancedCompose(middleware),
});

export const persistor = persistStore(store);
