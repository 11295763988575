import { DownloadIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	Flex,
	Heading,
	IconButton,
	Image,
	Link,
	SimpleGrid,
	Spinner,
	Tag,
	TagLabel,
	Text,
	Tooltip,
	useToast,
} from '@chakra-ui/react';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import {
	changeOfferStatus,
	getHomebuyerOffers,
	getHomebuyerOffers_Optimized,
} from 'services/offers.service';
import { OFFER_STATUS } from 'utils/constants';
import { handleDownload, convertHeicToJpg } from 'utils/helpers';

const OffersTab = ({ selectedUser }) => {
	const [loading, setLoading] = useState(false);
	const [offers, setOffers] = useState([]);

	const toast = useToast();

	const convertHeicFilesToJpg = async (documents) => {
		const temp = [...documents];

		await Promise.all(
			temp.map(async (doc, index) => {
				if (doc.type === 'image/heic') {
					let normalUrl = await convertHeicToJpg(doc.url);
					temp[index] = { ...temp[index], url: normalUrl };
				}
			})
		);

		return temp;
	};

	const getClientOffers = async () => {
		setLoading(true);

		try {
			let response = await getHomebuyerOffers_Optimized(
				selectedUser.id
			);

			let offers = [];

			for (let offer of response) {
				const { rentalDocuments, saleDocuments } = offer;
				const [rdRes, sdRes] = await Promise.all([
					convertHeicFilesToJpg(rentalDocuments),
					convertHeicFilesToJpg(saleDocuments),
				]);

				offers.push({
					...offer,
					rentalDocuments: rdRes,
					saleDocuments: sdRes,
					submissionDate: offer.timeSubmitted?.toDate(),
				});
			}
			offers = offers.sort((a, b) => {
				return b.submissionDate - a.submissionDate;
			});

			setOffers(offers);
		} catch (err) {
			toast({
				title: 'Error',
				description:
					'Something went wrong while getting user offers. Please try again.',
				status: 'error',
				duration: 4000,
				isClosable: true,
				position: 'bottom',
			});
		} finally {
			setLoading(false);
		}
	};

	const handleStatusChange = async (offerId, status, callback) => {
		setLoading(true);

		try {
			await changeOfferStatus(offerId, status);
			await getClientOffers();

			toast({
				title: 'Success',
				description: `Offer ${status} successfuly`,
				status: 'success',
				duration: 4000,
				isClosable: true,
				position: 'bottom',
			});
		} catch (err) {
			console.log(
				'🚀 ~ file: OffersTab.js:68 ~ handleStatusChange ~ err:',
				err
			);
			toast({
				title: 'Error',
				description:
					'Something went wrong while changing offer status. Please try again.',
				status: 'error',
				duration: 4000,
				isClosable: true,
				position: 'bottom',
			});
		} finally {
			// setShowButtons(false);
			callback && callback();

			setLoading(false);
		}
	};

	useEffect(() => {
		setOffers([]);
		if (selectedUser) {
			getClientOffers();
		}
	}, [selectedUser]);

	function renderDocuments(docType, title, data) {
		const docs = data?.[docType];
		const isDocsFound = docs?.length > 0;

		const openImageInNewTab = (imageUrl) => {
			window.open(imageUrl, '_blank');
		};

		return (
			<Box>
				<Heading
					my={3}
					size='sm'>
					{title}
				</Heading>
				{isDocsFound ? (
					<SimpleGrid
						columns={{ sm: 1, md: 2 }}
						spacing={4}>
						{docs.map((item, index) => {
							const isPdf = item.type === 'application/pdf';
							return (
								<Box
									p={4}
									borderWidth={1}
									borderRadius={10}
									borderColor='gray.200'
									key={index}
									_hover={{ shadow: 'lg' }}>
									{isPdf ? (
										<>
											<Text
												fontWeight='semibold'
												mb={2}>
												{index + 1}. Pdf file
											</Text>
											<Link
												href={item.url}
												isExternal
												color='blue.500'
												fontWeight='medium'
												fontSize='16px'>
												{item.name} <ExternalLinkIcon mx='2px' />
											</Link>
										</>
									) : (
										<>
											<Text
												fontWeight='semibold'
												mb={2}>
												{index + 1}. {item.name}
											</Text>
											<Box
												alignItems='flex-start'
												justifyContent='center'
												display='flex'>
												<Box position='relative'>
													<Image
														boxSize='200px'
														objectFit='cover'
														src={item.url}
														alt={item.name}
														borderRadius={5}
														onClick={() =>
															openImageInNewTab(item.url)
														}
													/>
													<Tooltip
														label='Download'
														hasArrow>
														<IconButton
															icon={<DownloadIcon />}
															size='sm'
															colorScheme='blue'
															borderRadius='50%'
															onClick={(e) => {
																handleDownload(item.url, item.name);
																e.stopPropagation();
															}}
															style={{
																position: 'absolute',
																top: '50%',
																left: '50%',
																transform: 'translate(-50%, -50%)',
															}}
														/>
													</Tooltip>
												</Box>
											</Box>
										</>
									)}
								</Box>
							);
						})}
					</SimpleGrid>
				) : (
					<Text>No Documents Found</Text>
				)}
			</Box>
		);
	}

	return (
		<Box>
			{loading ? (
				<Box
					flex={1}
					display='flex'
					alignItems={'center'}
					justifyContent={'center'}
					minHeight={'60vh'}>
					<Spinner size='xl' />
				</Box>
			) : offers.length > 0 ? (
				offers.map((offer, index) => {
					return (
						<OfferComp
							offer={offer}
							index={index}
							renderDocuments={renderDocuments}
							handleStatusChange={handleStatusChange}
						/>
					);
				})
			) : (
				<Text>No Offers Found For This Application</Text>
			)}
		</Box>
	);
};

const OfferComp = ({
	offer,
	index,
	renderDocuments,
	handleStatusChange,
}) => {
	const [showButtons, setShowButtons] = useState(true);

	const customFromNow = (date) => {
		const currentDate = moment().tz('America/New_York');
		const targetDate = moment(date).tz('America/New_York');
		const diffInMonths = currentDate.diff(targetDate, 'months');

		if (diffInMonths >= 1) {
			return targetDate.format('MMMM DD, YYYY');
		} else {
			return targetDate.fromNow();
		}
	};

	const isValidUrl = (address) => {
		try {
			new URL(address);
			return true;
		} catch (error) {
			return false;
		}
	};

	useEffect(() => {
		if (offer?.status) {
			setShowButtons(false);
		}
	}, []);
	return (
		<Box
			key={index.toString()}
			backgroundColor='rgba(0,0,0,0.1)'
			justifyContent={'space-between'}
			marginTop={4}
			py={2}
			px={2}
			alignItems={'center'}
			borderRadius={10}>
			<Flex
				alignItems={'center'}
				justifyContent={'space-between'}>
				<Heading
					my={3}
					size='md'>
					Offer # {index + 1}
				</Heading>

				{offer?.status && (
					<Tag
						size='lg'
						variant='outline'
						colorScheme={
							offer.status === OFFER_STATUS.ACCEPTED ? 'green' : 'red'
						}>
						<TagLabel>
							{offer.status === OFFER_STATUS.ACCEPTED
								? 'Accepted'
								: 'Rejected'}
						</TagLabel>
					</Tag>
				)}
			</Flex>
			<Flex>
				{isValidUrl(offer?.propertyAddress) ? (
					<Text>
						<Text fontWeight={'semibold'}>Address</Text>{' '}
						<Link
							href={offer?.propertyAddress}
							isExternal
							color='blue.500'>
							{offer?.propertyAddress}
						</Link>
					</Text>
				) : (
					<Text>
						<Text fontWeight={'semibold'}> Address</Text>{' '}
						{offer?.propertyAddress}
					</Text>
				)}
			</Flex>
			<Box>
				<Text>
					<Text fontWeight={'semibold'}>Property List Price</Text>
					{offer?.propertyListPrice?.toLocaleString()}
				</Text>
				<Text>
					<Text fontWeight={'semibold'}>Property Offer Price</Text>
					{offer?.propertyOfferPrice?.toLocaleString()}
				</Text>
				<Text>
					<Text fontWeight={'semibold'}>Submission Date</Text>
					{customFromNow(offer?.submissionDate)}
				</Text>
			</Box>

			{renderDocuments('rentalDocuments', 'Rental Documents', offer)}
			{renderDocuments('saleDocuments', 'Sales Documents', offer)}

			<Heading
				mt={3}
				size='sm'>
				Actions
			</Heading>

			{Boolean(offer?.status) && !showButtons ? (
				<>
					<Text>
						This offer has already been {offer.status}.
						<Text
							color={'blue.500'}
							cursor={'pointer'}
							onClick={() => {
								setShowButtons(true);
							}}>
							<u> Edit Anyway?</u>
						</Text>
					</Text>
				</>
			) : (
				<>
					{showButtons && (
						<>
							<Flex
								align='center'
								justifyContent='center'
								py={5}>
								<Button
									colorScheme='blue'
									variant='solid'
									mr={10}
									onClick={() => {
										handleStatusChange(
											offer?.uid,
											OFFER_STATUS.ACCEPTED,
											() => {
												setShowButtons(false);
											}
										);
									}}>
									Accept Offer
								</Button>
								<Button
									colorScheme='red'
									variant='solid'
									onClick={() => {
										handleStatusChange(
											offer?.uid,
											OFFER_STATUS.REJECTED,
											() => {
												setShowButtons(false);
											}
										);
									}}>
									Reject Offer
								</Button>
							</Flex>
							{Boolean(offer?.status) && (
								<Flex
									alignItems={'center'}
									justifyContent={'center'}>
									<Button
										colorScheme='teal'
										variant='ghost'
										onClick={() => {
											setShowButtons(false);
										}}>
										Cancel
									</Button>
								</Flex>
							)}
						</>
					)}
				</>
			)}
		</Box>
	);
};

export default OffersTab;
