/* eslint-disable */
import { NavLink, Route, useLocation } from 'react-router-dom';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Flex,
	HStack,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';

export const SidebarLinks = (props) => {
	let location = useLocation();
	let activeColor = useColorModeValue('gray.700', 'white');
	let inactiveColor = useColorModeValue(
		'secondaryGray.600',
		'secondaryGray.600'
	);
	let activeIcon = useColorModeValue('brand.500', 'white');
	let textColor = useColorModeValue('secondaryGray.500', 'white');
	let brandColor = useColorModeValue('brand.500', 'brand.400');
	const {
		allApplications,
		pendingApplications,
		approvedApplications,
		rejectedApplications,
		moreInfoRequestApplications,
		startedApplications,
		otheredApplications,
		inReviewApplications,
		pausedApplications,
		allSubmittedApplications,
		offerClosed,
		preclosingComplete,
		inspectionComplete,
		offerAccepted,
		offerExtended,
		conditionallyApproved,
		inactive,
		waitlistUsers,
		landlords,
	} = useSelector((state) => state.user);

	const { agents, invites, agentInvites, clientInvites } =
		useSelector((state) => state.agent);
	const { initiatedOffers } = useSelector((state) => state.offer);

	const { routes } = props;

	const getRouteLength = (path) => {
		let temp = path.split('/');
		let currentRoute = temp[temp.length - 1];

		let data = [];

		if (currentRoute === 'approved') {
			data = approvedApplications;
		} else if (currentRoute === 'inbox') {
			data = pendingApplications;
		} else if (currentRoute === 'info-required') {
			data = moreInfoRequestApplications;
		} else if (currentRoute === 'rejected') {
			data = rejectedApplications;
		} else if (currentRoute === 'started') {
			data = startedApplications;
		} else if (currentRoute === 'in-review') {
			data = inReviewApplications;
		} else if (currentRoute === 'paused') {
			data = pausedApplications;
		} else if (currentRoute === 'all-submitted') {
			data = allSubmittedApplications;
		} else if (currentRoute === 'conditionally-approved') {
			data = conditionallyApproved;
		} else if (currentRoute === 'inspection-complete') {
			data = inspectionComplete;
		} else if (currentRoute === 'preclosing-complete') {
			data = preclosingComplete;
		} else if (currentRoute === 'offer-extended') {
			data = offerExtended;
		} else if (currentRoute === 'offer-accepted') {
			data = offerAccepted;
		} else if (currentRoute === 'offer-closed') {
			data = offerClosed;
		} else if (currentRoute === 'inactive') {
			data = inactive;
		} else if (currentRoute === 'agents') {
			data = agents;
		} else if (currentRoute === 'client_invites') {
			data = invites;
		} else if (currentRoute === 'agent_invites') {
			data = agentInvites;
		} else if (currentRoute === 'offer-initiated') {
			data = initiatedOffers;
		} else if (currentRoute === 'homebuyer_invites') {
			data = clientInvites;
		} else if (currentRoute === 'othered') {
			data = otheredApplications;
		} else if (currentRoute === 'waitlist_users') {
			data = waitlistUsers;
		} else if (currentRoute === 'landlords') {
			data = landlords;
		} else {
			data = allApplications;
		}

		return data.length ? data.length : 0;
	};

	const activeRoute = (routeName) => {
		return location.pathname.includes(routeName);
	};

	const RouteComp = ({ route, index }) => {
		const isHome = route.path === '/dashboard';

		const length = getRouteLength(route.path);

		return (
			<NavLink
				key={index}
				to={route.layout + route.path}>
				{route.icon ? (
					<Box>
						<HStack
							spacing={
								activeRoute(route.path.toLowerCase())
									? '22px'
									: '26px'
							}
							py='5px'
							ps='10px'>
							<Flex
								w='100%'
								alignItems='center'
								justifyContent='center'>
								<Box
									color={
										activeRoute(route.path.toLowerCase())
											? activeIcon
											: textColor
									}
									me='18px'>
									{route.icon}
								</Box>
								<Text
									me='auto'
									color={
										activeRoute(route.path.toLowerCase())
											? activeColor
											: textColor
									}
									fontWeight={
										activeRoute(route.path.toLowerCase())
											? 'bold'
											: 'normal'
									}>
									{route.name}
									{'  '}
									{!isHome && `(${length})`}
								</Text>
							</Flex>
							<Box
								h='36px'
								w='4px'
								bg={
									activeRoute(route.path.toLowerCase())
										? brandColor
										: 'transparent'
								}
								borderRadius='5px'
							/>
						</HStack>
					</Box>
				) : (
					<Box>
						<HStack
							spacing={
								activeRoute(route.path.toLowerCase())
									? '22px'
									: '26px'
							}
							py='5px'
							ps='10px'>
							<Text
								me='auto'
								color={
									activeRoute(route.path.toLowerCase())
										? activeColor
										: inactiveColor
								}
								fontWeight={
									activeRoute(route.path.toLowerCase())
										? 'bold'
										: 'normal'
								}>
								{route.name}
							</Text>
							<Box
								h='36px'
								w='4px'
								bg='brand.400'
								borderRadius='5px'
							/>
						</HStack>
					</Box>
				)}
			</NavLink>
		);
	};

	const createLinks = (routes) => {
		return routes.map((route, index) => {
			if (
				(route.layout === '/admin' || route.layout === '/auth') &&
				route.name !== 'Initiated Offers Outside'
			) {
				if (route.subRoutes?.length > 0) {
					return (
						<>
							<Accordion
								defaultIndex={[]}
								allowMultiple
								// borderWidth={0}
								borderWidth='0px'
								borderColor={'transparent'}
								key={index}
								variant='unstyled'>
								<AccordionItem>
									<AccordionButton px={2}>
										<Box
											color={
												activeRoute(route.path.toLowerCase())
													? activeIcon
													: textColor
											}
											mt={2}>
											{route.icon}
										</Box>

										<Box
											flex='1'
											textAlign='left'
											ml={5}>
											<Text
												color={
													activeRoute(route.path.toLowerCase())
														? activeColor
														: inactiveColor
												}
												fontWeight={
													activeRoute(route.path.toLowerCase())
														? 'bold'
														: 'normal'
												}>
												{route.name}
											</Text>
										</Box>
										<AccordionIcon
											color={
												activeRoute(route.path.toLowerCase())
													? activeIcon
													: textColor
											}
										/>
									</AccordionButton>

									<AccordionPanel pb={4}>
										{route.subRoutes?.map((route, index) => (
											<RouteComp
												route={route}
												index={index}
												key={index}
											/>
										))}
									</AccordionPanel>
								</AccordionItem>
							</Accordion>
						</>
					);
				} else {
					return (
						<RouteComp
							route={route}
							index={index}
							key={index}
						/>
					);
				}
			}
		});
	};
	//  BRAND
	return createLinks(routes);
};

export default SidebarLinks;
