import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	fromDate: null,
	toDate: null,
	pieChartData: {
		order: [],
		data: [],
		percentage: [],
	},
	completeApplicationBarData: {
		categories: [],
		chartData: [],
	},
	inCompleteApplicationBarData: [],
	QualifiedAmountBarData: [],
	startedApplicationBarData: [],
	monthlyCompletedApplications: [],
	monthlyStartedApplications: [],
	monthlyOffersExtended: [],
	monthlyOffersAccepted: [],
	monthlyInspectionsCompleted: [],
	monthlyPreclosingsCompleted: [],
	monthlyOffersClosed: [],
	monthlyAgentJoinees: [],
	monthlyAgentAgentInvites: [],
	monthlyAgentClientInvites: [],
	monthlyClientClientInvites: [],
};

export const GraphSlice = createSlice({
	name: 'Graph',
	initialState,
	reducers: {
		resetAdminState: (state) => initialState,
		setPieChartData: (state, action) => {
			state.pieChartData = action.payload;
		},
		setApplicationCompletedData: (state, action) => {
			state.completeApplicationBarData = action.payload;
		},
		setIncompleteApplicationData: (state, action) => {
			state.inCompleteApplicationBarData = action.payload;
		},
		setQualifiedAmountBarData: (state, action) => {
			state.QualifiedAmountBarData = action.payload;
		},
		setStartedApplicationBarData: (state, action) => {
			state.startedApplicationBarData = action.payload;
		},

		setFromDate: (state, action) => {
			state.fromDate = action.payload;
		},
		setToDate: (state, action) => {
			state.toDate = action.payload;
		},
		setMonthlyStats: (state, action) => {
			state.monthlyCompletedApplications = action.payload.completed;
			state.monthlyStartedApplications = action.payload.started;
			state.monthlyOffersExtended = action.payload.offersExtended;
			state.monthlyOffersAccepted = action.payload.offersAccepted;
			state.monthlyInspectionsCompleted =
				action.payload.inspectionComplete;
			state.monthlyPreclosingsCompleted =
				action.payload.preclosingComplete;
			state.monthlyOffersClosed = action.payload.offerClosedData;
		},
		setMonthlyStats: (state, action) => {
			state.monthlyCompletedApplications = action.payload.completed;
			state.monthlyStartedApplications = action.payload.started;
			state.monthlyOffersExtended = action.payload.offersExtended;
			state.monthlyOffersAccepted = action.payload.offersAccepted;
			state.monthlyInspectionsCompleted =
				action.payload.inspectionComplete;
			state.monthlyPreclosingsCompleted =
				action.payload.preclosingComplete;
			state.monthlyOffersClosed = action.payload.offerClosedData;
		},
		setMonthlyAgentStats: (state, action) => {
			state.monthlyAgentJoinees = action.payload.agents;
			state.monthlyAgentAgentInvites = action.payload.agentInvited;
			state.monthlyAgentClientInvites = action.payload.clientInvites;
			state.monthlyClientClientInvites =
				action.payload.clientClientInvites;
		},
	},
});

export const {
	resetAdminState,
	setPieChartData,
	setApplicationCompletedData,
	setIncompleteApplicationData,
	setQualifiedAmountBarData,
	setFromDate,
	setToDate,
	setStartedApplicationBarData,
	setMonthlyStats,
	setMonthlyAgentStats,
} = GraphSlice.actions;

export default GraphSlice.reducer;
