import { DeleteIcon } from '@chakra-ui/icons';
import { Button, Text } from '@chakra-ui/react';
import CustomTable from 'components/customTable';
import moment from 'moment-timezone';
import React from 'react';

const InviteList = (props) => {
	const { data, userLoading, handleDelete } = props;

	const columns = React.useMemo(
		() => [
			{
				header: () => <span>#</span>,
				accessorKey: 'serialNo',
				enableSorting: false,
				cell: (row) => {
					return <div>{row?.row?.index + 1}</div>;
				},
			},

			{
				accessorKey: 'name',
				header: () => <span>Agent Name</span>,
				footer: (props) => props.column.id,
			},

			{
				accessorKey: 'email',
				header: () => <span>Agent Email</span>,
				footer: (props) => props.column.id,
			},
			{
				accessorKey: 'clientName',
				header: () => <span>Invited Agent</span>,
				footer: (props) => props.column.id,
			},
			{
				accessorKey: 'clientEmail',
				header: () => <span>Invited Agent Email</span>,
				footer: (props) => props.column.id,
			},
			{
				accessorKey: 'status',
				header: () => <span>Status</span>,
				footer: (props) => props.column.id,
			},

			{
				accessorKey: 'type',
				header: () => <span>Via</span>,
				footer: (props) => props.column.id,
			},

			{
				accessorKey: 'createdAt',
				header: () => <span>Invited At</span>,
				footer: (props) => props.column.id,
				sortType: 'datetime',
				cell: (row) => {
					let value = row.getValue();
					return (
						<Text>
							{value
								? moment(value)
										.tz('America/New_York')
										.format('DD MMM YYYY,  hh:mm a')
								: ''}
						</Text>
					);
				},
			},

			{
				accessorKey: 'actions',
				header: () => <span>Actions</span>,
				footer: (props) => props.column.id,
				cell: (row) => {
					return (
						<Button
							width={'100%'}
							maxH={'25px'}
							background='transparent'>
							<DeleteIcon color='red.400' />
						</Button>
					);
				},
			},
		],
		[]
	);

	return (
		<>
			<CustomTable
				data={data}
				columns={columns}
				loading={userLoading}
				onActionPress={(data) => {
					handleDelete(data.uid, data.clientName);
				}}
			/>
		</>
	);
};

export default InviteList;
