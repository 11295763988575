import { Box, Button, Flex, useDisclosure, useToast } from "@chakra-ui/react";
import ConfirmationDialog from "components/confirmationDialog";
import { DatePicker } from "components/datePicker/DatePicker";
import { useAuth } from "contexts/AuthContext";
import moment from "moment-timezone";
import { useMemo, useState } from "react";
import CsvDownloadButton from "react-json-to-csv";
import { useDispatch, useSelector } from "react-redux";
import { changeAgentStatusToDeleted } from "services/agents.service";
import { getUserFilesById } from "services/applications.service";
import { setAgents } from "store/reducers/agentSlice";
import UsersList from "./components/UsersList";
import * as dayjs from "dayjs";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");

const tz = "America/New_York";

dayjs.extend(utc);
dayjs.extend(timezone);

const Agents = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [applicationData, setApplicationData] = useState(null);
  const [storageData, setStorageData] = useState();
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dialogLoading, setDialogLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [userName, setUserName] = useState("");
  const [filteredData, setFilteredData] = useState(null);

  const toast = useToast();
  const { agents } = useSelector((state) => state.agent);

  const { searchResults } = useAuth();

  const dispatch = useDispatch();

  const getStorageData = async (userId) => {
    try {
      setLoading(true);
      const storageRes = await getUserFilesById(userId);
      setStorageData(storageRes);
      setLoading(false);
    } catch (err) {
      setLoading(false);

      toast({
        title: "Error",
        description:
          "Something went wrong while getting user files. Please try again",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const removeUserFromUsers = (userId) => {
    const ind = agents.findIndex((user) => {
      return user.id === userId;
    });
    let temp = [...agents];
    temp.splice(ind, 1);
    dispatch(setAgents(temp));
  };

  const handleConfirmationOpen = (userId, name) => {
    setUserName(name);
    setDeleteId(userId);
    onOpen();
  };

  const handleDelete = async () => {
    try {
      setDialogLoading(true);
      await changeAgentStatusToDeleted(deleteId);
      removeUserFromUsers(deleteId);
      setDeleteId(null);
      setDialogLoading(false);
      onClose();
      toast({
        title: "Success",
        description: "User deleted successfully",
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    } catch (err) {
      setDeleteId(null);
      setDialogLoading(false);
      onClose();

      toast({
        title: "Error",
        description:
          "Unable to delete application at the moment. Please try again later",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const [downloadData, downloadHeaders] = useMemo(() => {
    let data = filteredData ?? agents;
    let downloadData = data.map((val, index) => {
      return {
        "#": index,
        id: val.displayId,
        firstName: val.firstName,
        lastName: val.lastName,
        email: val.email,
        phone: val.phoneNumber,
        applicationCompleted: val.applicationCompleted,
        applicationStarted: val.applicationStarted,
        applicationStatus: val.applicationStatus,
        submissionDate: val.submissionDate,
      };
    });

    const headers = [
      "#",
      "id",
      "firstName",
      "lastName",
      "email",
      "phone",
      "applicationCompleted",
      "applicationStarted",
      "applicationStatus",
      "preQualifiedAmount",
      "submissionDate",
    ];

    return [downloadData, headers];
  }, [agents, filteredData]);

  const handleDateFilter = (values) => {
    console.log("RUNNING handleDateFilter()");
    if (values?.start && values?.end) {
      const startDate = new Date(values.start);
      const endDate = new Date(values.end);
      // endDate.setHours(23, 59, 59, 999);

      const fromDate = dayjs(startDate).tz(tz, true).toDate();
      const toDate = dayjs(endDate).tz(tz, true).toDate();

      // const response = agents?.filter((item) => {
      // 	let dateKey = item.submissionDate;

      // 	const submissionDate = new Date(dateKey);

      // 	// console.log({ rawDate: values.start, startDate: startDate });

      // 	// Convert submissionDate, startDate, and endDate to UTC before comparison
      // 	// const submissionDateUTC = Date.UTC(
      // 	// 	submissionDate.getFullYear(),
      // 	// 	submissionDate.getMonth(),
      // 	// 	submissionDate.getDate()
      // 	// );
      // 	// const startDateUTC = Date.UTC(
      // 	// 	startDate.getFullYear(),
      // 	// 	startDate.getMonth(),
      // 	// 	startDate.getDate()
      // 	// );
      // 	// const endDateUTC = Date.UTC(
      // 	// 	endDate.getFullYear(),
      // 	// 	endDate.getMonth(),
      // 	// 	endDate.getDate()
      // 	// );
      // 	const submissionDateUTC = new Date(
      // 		submissionDate.getFullYear(),
      // 		submissionDate.getMonth(),
      // 		submissionDate.getDate()
      // 	).getTime();
      // 	const startDateUTC = new Date(
      // 		startDate.getFullYear(),
      // 		startDate.getMonth(),
      // 		startDate.getDate()
      // 	).getTime();
      // 	const endDateUTC = new Date(
      // 		endDate.getFullYear(),
      // 		endDate.getMonth(),
      // 		endDate.getDate()
      // 	).getTime();

      // 	// console.log({ submissionDateUTC, startDateUTC, endDateUTC });

      // 	return (
      // 		submissionDateUTC >= startDateUTC &&
      // 		submissionDateUTC <= endDateUTC
      // 	);
      // });

      let dateRange_agentsAll = [];
      while (fromDate <= toDate) {
        const numAgentsInDateRange = agents.filter((user) => {
          return (
            (user?.applicationStartDate?.getDate() === fromDate.getDate() &&
              user?.applicationStartDate?.getMonth() === fromDate.getMonth() &&
              user?.applicationStartDate?.getFullYear() ===
                fromDate.getFullYear()) ||
            (user?.createdAt?.getDate() === fromDate.getDate() &&
              user?.createdAt?.getMonth() === fromDate.getMonth() &&
              user?.createdAt?.getFullYear() === fromDate.getFullYear())
          );
        });
        for (let i = 0; i < numAgentsInDateRange.length; i++) {
          dateRange_agentsAll.push(numAgentsInDateRange[i]);
        }

        fromDate.setDate(fromDate.getDate() + 1);
      }

      console.log({ dateRange_agentsAll });

      setFilteredData(dateRange_agentsAll);
    }
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ConfirmationDialog
        title="Delete Application"
        message={`Are you sure you want to delete <b>${userName}</b> application?`}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        loading={dialogLoading}
        onConfirm={() => handleDelete()}
      />

      <Flex justifyContent={"space-between"} mb={2}>
        <Button>
          <CsvDownloadButton
            data={downloadData}
            filename={`Agents-Export-${moment(new Date())
              .tz("America/New_York")
              .format("DD/MM/YY")}`}
            delimiter=","
            headers={downloadHeaders}
          />
        </Button>
        <DatePicker
          closeAction={handleDateFilter}
          clearAction={() => {
            setFilteredData(null);
          }}
        />
      </Flex>

      <UsersList
        data={
          searchResults ? searchResults : filteredData ? filteredData : agents
        }
        setSelectedUser={setSelectedUser}
        storageData={storageData}
        setStorageData={setStorageData}
        selectedUser={selectedUser}
        loading={loading}
        applicationData={applicationData}
        setApplicationData={setApplicationData}
        getStorageData={getStorageData}
        handleDelete={handleConfirmationOpen}

        // userLoading={userLoading}
      />
    </Box>
  );
};

export default Agents;
