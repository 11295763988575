import { ChakraProvider } from '@chakra-ui/react';
import App from 'App';
import 'assets/css/App.css';
import React from 'react';
import ReactDOM from 'react-dom';
import theme from 'theme/theme';
import { Provider } from 'react-redux';
import { store } from 'store/store';

// providers
import { AuthProvider } from './contexts/AuthContext';
import { TimezoneProvider } from 'contexts/TimezoneContext';

ReactDOM.render(
	<TimezoneProvider timezone='America/New_York'>
		<ChakraProvider theme={theme}>
			<Provider store={store}>
				<AuthProvider>
					<React.StrictMode>
						<App />
					</React.StrictMode>
				</AuthProvider>
			</Provider>
		</ChakraProvider>
	</TimezoneProvider>,
	document.getElementById('root')
);
