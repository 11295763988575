import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  initiatedOffers: [],
};

export const OfferSlice = createSlice({
  name: "Offer",
  initialState,
  reducers: {
    resetOfferState: (state) => initialState,
    setInitiatedOffers: (state, action) => {
      state.initiatedOffers = action.payload;
    },
  },
});

export const { resetOfferState, setInitiatedOffers } = OfferSlice.actions;

export default OfferSlice.reducer;
