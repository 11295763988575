// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCGbiIGIjtPn66vJ-x4d8PspahDferywpw",
  authDomain: "utopia-prod-8c73d.firebaseapp.com",
  projectId: "utopia-prod-8c73d",
  storageBucket: "utopia-prod-8c73d.appspot.com",
  messagingSenderId: "932652361381",
  appId: "1:932652361381:web:46aeb531b101aed5c98f4c",
  measurementId: "G-823DQP6GEX",
};
// const firebaseConfig = {
//   apiKey: "AIzaSyBeFPLn_uolLO2zAZ8tSPvRr7_i520RneM",
//   authDomain: "utopia-test-2323e.firebaseapp.com",
//   projectId: "utopia-test-2323e",
//   storageBucket: "utopia-test-2323e.appspot.com",
//   messagingSenderId: "261631741609",
//   appId: "1:261631741609:web:69e68c9297214b520ba3b8",
//   measurementId: "G-2QSSLXSED8x",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;

// Initialize services
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
// const analytics = getAnalytics(app)

export { db, auth, storage };
