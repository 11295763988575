export const USER_TYPES = {
  AGENT: "agent",
  HOME_OWNER: "home_owner",
};

export const APPLICATION_STATUS = {
  APPROVED: "approved",
  CONDITIONALLY_APROVED: "conditionallyApproved",
  REJECTED: "rejected",
  PAUSED: "paused",
  IN_REVIEW: "inReview",
  MORE_INFO_REQUIRED: "moreInfoRequired",
  OFFER_EXTENDED: "offerExtended",
  OFFER_ACCEPTED: "offerAccepted",
  INSPECTION_COMPLETE: "inspectionComplete",
  PRECLOSING_COMPLETE: "preclosingComplete",
  OFFER_CLOSED: "offerClosed",
  INACTIVE: "inactive",
};

export const OFFER_STATUS = {
  ACCEPTED: "accepted",
  REJECTED: "rejected",
};
