import { Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import BarChart from "components/charts/BarChart";

const BarWrapper = (props) => {
  const { data, title, ...rest } = props;

  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <Card p="20px" w="100%" {...rest}>
      <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
        {title}
      </Text>
      {data && (
        <BarChart categories={data?.categories} chartData={data?.chartData} />
      )}
    </Card>
  );
};

export default BarWrapper;
