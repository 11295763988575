import {
	Box,
	Button,
	Flex,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import ConfirmationDialog from 'components/confirmationDialog';
import { DatePicker } from 'components/datePicker/DatePicker';
import { useAuth } from 'contexts/AuthContext';
import moment from 'moment-timezone';
import { useEffect, useMemo, useState } from 'react';
import CsvDownloadButton from 'react-json-to-csv';
import { useDispatch, useSelector } from 'react-redux';
import {
	changeInviteStatusToDeleted,
	getAgentInvites,
} from 'services/agents.service';
import { setClientInvites } from 'store/reducers/agentSlice';
import UsersList from './components/InviteList';

const HomebuyerInvites = () => {
	const [deleteId, setDeleteId] = useState(null);
	const [userName, setUserName] = useState('');
	const [dialogLoading, setDialogLoading] = useState(false);
	const [filteredData, setFilteredData] = useState(null);
	const { clientInvites: invites } = useSelector(
		(state) => state.agent
	);

	const { isOpen, onOpen, onClose } = useDisclosure();
	const { searchResults, setSearchresults } = useAuth();
	const toast = useToast();
	const dispatch = useDispatch();

	const removeDeletedInvite = (inviteId) => {
		const filtered = invites.filter(
			(invite) => invite.uid !== inviteId
		);
		dispatch(setClientInvites(filtered));

		if (searchResults) {
			const filter = searchResults.filter(
				(invite) => invite.uid !== inviteId
			);
			setSearchresults(filter);
		}
	};

	const handleDelete = async () => {
		try {
			setDialogLoading(true);
			await changeInviteStatusToDeleted(deleteId);
			removeDeletedInvite(deleteId);
			// await getAgentInvites();

			setDeleteId(null);
			setDialogLoading(false);
			onClose();
			toast({
				title: 'Success',
				description: 'Invite deleted successfully',
				status: 'success',
				duration: 4000,
				isClosable: true,
				position: 'top-right',
			});
		} catch (err) {
			console.log(
				'🚀 ~ file: index.jsx:37 ~ handleDelete ~ err:',
				err
			);
			setDeleteId(null);
			setDialogLoading(false);
			onClose();

			toast({
				title: 'Error',
				description:
					'Unable to delete application at the moment. Please try again later',
				status: 'error',
				duration: 4000,
				isClosable: true,
				position: 'top-right',
			});
		}
	};

	const handleConfirmationOpen = (userId, name) => {
		setUserName(name);
		setDeleteId(userId);
		onOpen();
	};

	const [downloadData, downloadHeaders] = useMemo(() => {
		const data = filteredData ?? invites;

		let downloadData = data.map((val, index) => {
			return {
				'#': index,
				agentName: val.name,
				agentEmail: val.email,
				clientName: val.clientName,
				clientEmail: val.clientEmail,
				status: val.status,
				via: val.type,
				invitedAt: val.createdAt,
				isRejected: val.isRejected,
			};
		});

		const headers = [
			'#',
			'agentName',
			'agentEmail',
			'clientName',
			'clientEmail',
			'status',
			'via',
			'invitedAt',
			'isRejected',
		];

		return [downloadData, headers];
	}, [invites, filteredData]);

	const handleDateFilter = (values) => {
		if (values?.start && values?.end) {
			const startDate = new Date(values.start);
			const endDate = new Date(values.end);
			endDate.setHours(23, 59, 59, 999);

			const response = invites?.filter((item) => {
				let dateKey = item.createdAt;

				const submissionDate = new Date(dateKey);

				// Convert submissionDate, startDate, and endDate to UTC before comparison
				const submissionDateUTC = Date.UTC(
					submissionDate.getFullYear(),
					submissionDate.getMonth(),
					submissionDate.getDate()
				);
				const startDateUTC = Date.UTC(
					startDate.getFullYear(),
					startDate.getMonth(),
					startDate.getDate()
				);
				const endDateUTC = Date.UTC(
					endDate.getFullYear(),
					endDate.getMonth(),
					endDate.getDate()
				);

				return (
					submissionDateUTC >= startDateUTC &&
					submissionDateUTC <= endDateUTC
				);
			});

			setFilteredData(response);
		}
	};

	useEffect(() => {
		getAgentInvites();
	}, []);

	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<ConfirmationDialog
				title='Delete Invite'
				message={`Are you sure you want to delete <b>${userName}</b> invite?`}
				isOpen={isOpen}
				onOpen={onOpen}
				onClose={onClose}
				loading={dialogLoading}
				onConfirm={() => handleDelete()}
			/>
			<Flex
				justifyContent={'space-between'}
				mb={2}>
				<Button>
					<CsvDownloadButton
						data={downloadData}
						filename={`Agents-Client-Invites-Export-${moment(
							new Date()
						)
							.tz('America/New_York')
							.format('DD/MM/YY')}`}
						delimiter=','
						headers={downloadHeaders}
					/>
				</Button>

				<DatePicker
					closeAction={handleDateFilter}
					clearAction={() => {
						setFilteredData(null);
					}}
				/>
			</Flex>

			<UsersList
				data={
					searchResults
						? searchResults
						: filteredData
						? filteredData
						: invites
				}
				handleDelete={handleConfirmationOpen}
			/>
		</Box>
	);
};

export default HomebuyerInvites;
