import { ChevronDownIcon, EditIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	ButtonGroup,
	Divider,
	Heading,
	IconButton,
	Input,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Spinner,
	Text,
	Textarea,
	useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
	getUserByEmail,
	updateApplicationResponseFields,
	updateInvitationStatus,
	updateUserFields,
	getUserById,
	getUserByEmail_Optimized,
} from 'services/applications.service';
import { APPLICATION_STATUS, USER_TYPES } from 'utils/constants';

const ManagmentTab = ({ selectedUser, applicationData }) => {
	const [isEditing, setIsEditing] = useState(false);
	const [selectedStatus, setSelectedStatus] = useState(null);
	const [qAmount, setQAmount] = useState('');
	const [note, setNote] = useState('');
	const [updateLoading, setUpdateLoading] = useState(false);
	const [userAgent, setUserAgent] = useState(null);
	const [isAgentEditing, setIsAgentEditing] = useState(false);
	const [agentEmail, setAgentEmail] = useState('no agent assigned');
	const [agentUpdateLoading, setAgentUpdateLoading] = useState(false);

	const toast = useToast();

	const handleApplicationManagment = async () => {
		setUpdateLoading(true);

		try {
			await updateUserFields(
				{
					qualifiedAmount: qAmount,
				},
				applicationData?.uid
			);

			await updateApplicationResponseFields(
				{
					status: selectedStatus,
					note,
				},
				applicationData?.uid
			);
			updateInvitationStatus(selectedUser.email, selectedStatus);

			setIsEditing(false);

			toast({
				title: 'Success',
				description: 'Application updated successfully',
				status: 'success',
				duration: 4000,
				isClosable: true,
				position: 'bottom',
			});
			setUpdateLoading(false);
		} catch (err) {
			toast({
				title: 'Error',
				description:
					'Something went wrong while updating application. Please try again.',
				status: 'error',
				duration: 4000,
				isClosable: true,
				position: 'bottom',
			});

			setUpdateLoading(false);
		}
	};

	const isValidEmail = (email) => {
		const emailRegex = /\S+@\S+\.\S+/;
		return emailRegex.test(email);
	};

	const handleAgentAssignment = async () => {
		if (!isValidEmail(agentEmail)) {
			toast({
				// title: "Failed",
				description: 'invalid email address',
				status: 'error',
				duration: 4000,
				isClosable: true,
				position: 'bottom',
			});

			return;
		}
		setAgentUpdateLoading(true);
		try {
			const agentDetails = await getUserByEmail_Optimized(
				agentEmail?.trim().toLowerCase()
			);

			if (!agentDetails) {
				toast({
					title: 'Failed',
					description: 'No user found with given email address',
					status: 'error',
					duration: 4000,
					isClosable: true,
					position: 'bottom',
				});
				setAgentUpdateLoading(false);

				return;
			}

			if (agentDetails.type !== USER_TYPES.AGENT) {
				toast({
					title: 'Failed',
					description: 'Provided user is not an agent',
					status: 'error',
					duration: 4000,
					isClosable: true,
					position: 'bottom',
				});
				setAgentUpdateLoading(false);

				return;
			}

			await updateUserFields(
				{
					agent: agentDetails?.id,
				},
				applicationData?.uid
			);

			setUserAgent(agentDetails);
			setAgentEmail(agentDetails.email);

			setIsAgentEditing(false);
			setAgentUpdateLoading(false);

			toast({
				title: 'Success',
				description: 'Agent Assigned Successfully',
				status: 'success',
				duration: 4000,
				isClosable: true,
				position: 'bottom',
			});
		} catch (err) {
			setAgentUpdateLoading(false);

			toast({
				title: 'Failed',
				description: 'Something went wrong while assigning agent',
				status: 'error',
				duration: 4000,
				isClosable: true,
				position: 'bottom',
			});
		}
	};

	const fetchUserAgentDetails = async () => {
		try {
			if (selectedUser.agent) {
				const agent = await getUserById(selectedUser.agent);
				if (agent) {
					setUserAgent(agent);
					setAgentEmail(agent.email);
				} else {
					setAgentEmail('no agent assigned');
				}
			}
		} catch (err) {
			toast({
				title: 'Something went wrong',
				description:
					'Unable to fetch user agent details at the moment. Please try again',
				status: 'success',
				duration: 4000,
				isClosable: true,
				position: 'bottom',
			});
		}
	};

	useEffect(() => {
		setAgentEmail('no agent assigned');
		setUserAgent(null);

		fetchUserAgentDetails();

		setSelectedStatus(applicationData?.status || 'pending');
		setNote(applicationData?.note || '');
		setQAmount(selectedUser?.qualifiedAmount || 0);
		// setApplicationDetails(finalData);
	}, [selectedUser]);

	return (
		<>
			<Box
				display='flex'
				alignItems='center'
				justifyContent='space-between'
				mt={4}>
				<Heading fontSize='22px'>User Agent Managment</Heading>

				<Box>
					{/* <Heading fontSize="22px">Application Managment</Heading>
                  <EditIcon /> */}
					<IconButton
						icon={<EditIcon />}
						title='Edit'
						onClick={() => setIsAgentEditing(true)}
					/>
				</Box>
			</Box>

			<Text
				my={2}
				fontWeight='medium'>
				Agent Email
			</Text>
			<Input
				value={agentEmail}
				onChange={(e) => {
					setAgentEmail(e.target.value);
				}}
				isReadOnly={!isAgentEditing}
			/>

			{isAgentEditing && (
				<ButtonGroup
					mt={10}
					display='flex'
					alignItems='center'
					justifyContent='center'>
					<Button
						colorScheme='blue'
						px={10}
						onClick={handleAgentAssignment}>
						{agentUpdateLoading ? <Spinner /> : 'Update'}
					</Button>

					<Button
						colorScheme='red'
						px={10}
						onClick={() => {
							setIsAgentEditing(false);

							setAgentEmail(
								userAgent ? userAgent.email : 'no agent assigned'
							);
						}}>
						Cancel
					</Button>
				</ButtonGroup>
			)}

			{applicationData && (
				<>
					<Box
						display='flex'
						alignItems='center'
						justifyContent='space-between'
						mt={5}>
						<Heading fontSize='22px'>Application Managment</Heading>
						<Box>
							<IconButton
								icon={<EditIcon />}
								title='Edit'
								onClick={() => setIsEditing(true)}
							/>
						</Box>
					</Box>

					<Text
						my={2}
						fontWeight='medium'>
						Qualified Amount
					</Text>
					<Input
						placeholder='Enter User Qualified Amount'
						type='number'
						defaultValue={qAmount}
						onChange={(e) => {
							setQAmount(e.target.value);
						}}
						isReadOnly={!isEditing}
					/>

					<Text
						my={2}
						fontWeight='medium'>
						Application Status
					</Text>

					<Box
						display={'flex'}
						alignItems='center'
						borderWidth={0.5}
						px={3}
						borderRadius={10}
						justifyContent='space-between'
						py={2}>
						{isEditing ? (
							<Menu>
								<MenuButton
									as={Button}
									rightIcon={<ChevronDownIcon />}>
									{selectedStatus}
								</MenuButton>
								<MenuList>
									<MenuItem
										onClick={() =>
											setSelectedStatus(
												APPLICATION_STATUS.CONDITIONALLY_APROVED
											)
										}>
										Conditionally Approved
									</MenuItem>

									<MenuItem
										onClick={() =>
											setSelectedStatus(APPLICATION_STATUS.APPROVED)
										}>
										Fully Approved
									</MenuItem>

									<MenuItem
										onClick={() =>
											setSelectedStatus(
												APPLICATION_STATUS.OFFER_EXTENDED
											)
										}>
										Offer Extended
									</MenuItem>

									<MenuItem
										onClick={() =>
											setSelectedStatus(
												APPLICATION_STATUS.OFFER_ACCEPTED
											)
										}>
										Offer Accepted
									</MenuItem>

									<MenuItem
										onClick={() =>
											setSelectedStatus(
												APPLICATION_STATUS.INSPECTION_COMPLETE
											)
										}>
										Inspection Complete
									</MenuItem>

									<MenuItem
										onClick={() =>
											setSelectedStatus(
												APPLICATION_STATUS.PRECLOSING_COMPLETE
											)
										}>
										Preclosing Complete
									</MenuItem>

									<MenuItem
										onClick={() =>
											setSelectedStatus(
												APPLICATION_STATUS.OFFER_CLOSED
											)
										}>
										Offer Closed
									</MenuItem>

									<MenuItem
										onClick={() =>
											setSelectedStatus(APPLICATION_STATUS.INACTIVE)
										}>
										Inactive
									</MenuItem>

									<MenuItem
										onClick={() =>
											setSelectedStatus(APPLICATION_STATUS.REJECTED)
										}>
										Rejected
									</MenuItem>
									<MenuItem
										onClick={() =>
											setSelectedStatus(APPLICATION_STATUS.PAUSED)
										}>
										Paused
									</MenuItem>
									<MenuItem
										onClick={() =>
											setSelectedStatus(APPLICATION_STATUS.IN_REVIEW)
										}>
										In review
									</MenuItem>
									<MenuItem
										onClick={() =>
											setSelectedStatus(
												APPLICATION_STATUS.MORE_INFO_REQUIRED
											)
										}>
										More Information Required
									</MenuItem>

									<Divider />
								</MenuList>
							</Menu>
						) : (
							<Input
								readOnly
								value={selectedStatus}
								variant='unstyled'
								contentEditable={false}
							/>
						)}
					</Box>

					<Text
						my={2}
						fontWeight='medium'>
						Notes
					</Text>
					<Textarea
						placeholder='Notes'
						type='number'
						defaultValue={note}
						onChange={(e) => {
							setNote(e.target.value);
						}}
						isReadOnly={!isEditing}
					/>

					{isEditing && (
						<ButtonGroup
							mt={10}
							display='flex'
							alignItems='center'
							justifyContent='center'>
							<Button
								colorScheme='blue'
								px={10}
								onClick={handleApplicationManagment}>
								{updateLoading ? <Spinner /> : 'Update'}
							</Button>

							<Button
								colorScheme='red'
								px={10}
								onClick={() => setIsEditing(false)}>
								Cancel
							</Button>
						</ButtonGroup>
					)}
				</>
			)}
		</>
	);
};

export default ManagmentTab;
