import axios from 'axios';
import { APPLICATION_STATUS } from './constants';
import heic2any from 'heic2any';
import { setUsers } from 'store/reducers/usersSlice';
import { store } from 'store/store';

const handleDownload = async (url, name) => {
	const response = await axios.get(url, {
		responseType: 'arraybuffer',
	});

	let buffer = response.data;
	const newUrl = window.URL.createObjectURL(new Blob([buffer]));
	const link = document.createElement('a');
	link.href = newUrl;
	link.setAttribute('download', name); //or any other extension
	document.body.appendChild(link);
	link.click();
};

const convertCommaSeperatedToNormalNumber = (value) => {
	return parseFloat(String(value).replace(/,/g, ''));
};

const getApplicationStatus = (applicationStatus) => {
	let status;

	switch (applicationStatus) {
		case APPLICATION_STATUS.APPROVED:
			status = 'Approved';
			break;
		case APPLICATION_STATUS.CONDITIONALLY_APROVED:
			status = 'Conditionally Approved';
			break;
		case APPLICATION_STATUS.REJECTED:
			status = 'Rejected';
			break;
		case APPLICATION_STATUS.MORE_INFO_REQUIRED:
			status = 'Prequalified';
			break;
		case APPLICATION_STATUS.PAUSED:
			status = 'Paused';
			break;
		case APPLICATION_STATUS.IN_REVIEW:
			status = 'Prequalified';
			break;
		case APPLICATION_STATUS.OFFER_EXTENDED:
			status = 'Offer Extended';
			break;
		case APPLICATION_STATUS.OFFER_ACCEPTED:
			status = 'Offer Accepted';
			break;
		case APPLICATION_STATUS.INSPECTION_COMPLETE:
			status = 'Inspection Complete';
			break;
		case APPLICATION_STATUS.PRECLOSING_COMPLETE:
			status = 'Preclosing Complete';
			break;
		case APPLICATION_STATUS.OFFER_CLOSED:
			status = 'Offer Closed';
			break;
		case APPLICATION_STATUS.INACTIVE:
			status = 'Inactive';
			break;
		default:
			status = applicationStatus ?? 'Invited';
	}
	return status;
};

const convertHeicToJpg = async (heicUrl) => {
	try {
		const jpgBlob = await heic2any({
			blob: await fetch(heicUrl).then((res) => res.blob()),
		});
		const jpgUrl = URL.createObjectURL(jpgBlob);

		return jpgUrl;
	} catch (error) {
		console.error('Error converting HEIC to JPG:', error);
		return null;
	}
};

const handleDeleteFromLocal = (applications, deletedApplication) => {
	const filteredApplications = applications.filter(
		(application) => application.id !== deletedApplication
	);

	let pending = [],
		rejected = [],
		approved = [],
		all = [],
		moreInfo = [],
		started = [],
		allSubmitted = [],
		paused = [],
		inReview = [],
		conditionallyApproved = [],
		offerExtended = [],
		offerAccepted = [],
		inspectionComplete = [],
		preclosingComplete = [],
		offerClosed = [],
		inactive = [];

	const allData = filteredApplications.map((application) => {
		const currentStatus = application?.application?.status;

		if (currentStatus === APPLICATION_STATUS.APPROVED) {
			approved.push(application);
			allSubmitted.push(application);
		} else if (
			currentStatus === APPLICATION_STATUS.CONDITIONALLY_APROVED
		) {
			conditionallyApproved.push(application);
			allSubmitted.push(application);
		} else if (currentStatus === APPLICATION_STATUS.REJECTED) {
			rejected.push(application);
			allSubmitted.push(application);
		} else if (
			currentStatus === APPLICATION_STATUS.MORE_INFO_REQUIRED
		) {
			moreInfo.push(application);
			allSubmitted.push(application);
		} else if (currentStatus === APPLICATION_STATUS.PAUSED) {
			paused.push(application);
			allSubmitted.push(application);
		} else if (currentStatus === APPLICATION_STATUS.IN_REVIEW) {
			inReview.push(application);
			allSubmitted.push(application);
		} else if (currentStatus === APPLICATION_STATUS.OFFER_EXTENDED) {
			offerExtended.push(application);
			allSubmitted.push(application);
		} else if (currentStatus === APPLICATION_STATUS.OFFER_ACCEPTED) {
			offerAccepted.push(application);
			allSubmitted.push(application);
		} else if (
			currentStatus === APPLICATION_STATUS.INSPECTION_COMPLETE
		) {
			inspectionComplete.push(application);
			allSubmitted.push(application);
		} else if (
			currentStatus === APPLICATION_STATUS.PRECLOSING_COMPLETE
		) {
			preclosingComplete.push(application);
			allSubmitted.push(application);
		} else if (currentStatus === APPLICATION_STATUS.OFFER_CLOSED) {
			offerClosed.push(application);
			allSubmitted.push(application);
		} else if (currentStatus === APPLICATION_STATUS.INACTIVE) {
			inactive.push(application);
			allSubmitted.push(application);
		} else if (!application?.applicationCompleted) {
			started.push(application);
		} else {
			pending.push(application);
			allSubmitted.push(application);
		}

		all.push(application);

		return application;
	});

	all = all.sort((a, b) => {
		return b.submissionDate - a.submissionDate;
	});

	approved = approved.sort((a, b) => {
		return b.submissionDate - a.submissionDate;
	});

	conditionallyApproved = conditionallyApproved.sort((a, b) => {
		return b.submissionDate - a.submissionDate;
	});

	allSubmitted = allSubmitted.sort((a, b) => {
		return b.submissionDate - a.submissionDate;
	});

	rejected = rejected.sort((a, b) => {
		return b.submissionDate - a.submissionDate;
	});

	moreInfo = moreInfo.sort((a, b) => {
		return b.submissionDate - a.submissionDate;
	});

	paused = paused.sort((a, b) => {
		return b.submissionDate - a.submissionDate;
	});

	inReview = inReview.sort((a, b) => {
		return b.submissionDate - a.submissionDate;
	});

	offerExtended = offerExtended.sort((a, b) => {
		return b.submissionDate - a.submissionDate;
	});

	offerAccepted = offerAccepted.sort((a, b) => {
		return b.submissionDate - a.submissionDate;
	});

	inspectionComplete = inspectionComplete.sort((a, b) => {
		return b.submissionDate - a.submissionDate;
	});

	preclosingComplete = preclosingComplete.sort((a, b) => {
		return b.submissionDate - a.submissionDate;
	});

	offerClosed = offerClosed.sort((a, b) => {
		return b.submissionDate - a.submissionDate;
	});

	started = started.sort((a, b) => {
		return b.applicationStartData - a.applicationStartData;
	});

	inactive = inactive.sort((a, b) => {
		return b.applicationStartData - a.applicationStartData;
	});

	store.dispatch(
		setUsers({
			approved,
			pending,
			rejected,
			all,
			moreInfo,
			started,
			allSubmitted,
			inReview,
			paused,
			conditionallyApproved,
			offerExtended,
			offerAccepted,
			inspectionComplete,
			preclosingComplete,
			offerClosed,
			inactive,
		})
	);

	return allData;
};

const changeDateFormat = (dateString) => {
	let parts = dateString.split('-');
	let year = parts[0];
	let month = parts[1];
	let day = parts[2];

	month = month.padStart(2, '0');
	day = day.padStart(2, '0');

	return `${month}/${day}/${year}`;
};

export {
	handleDownload,
	convertCommaSeperatedToNormalNumber,
	getApplicationStatus,
	convertHeicToJpg,
	handleDeleteFromLocal,
	changeDateFormat,
};
