import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";

import Card from "components/card/Card.js";
import PieChart from "components/charts/PieChart";
import { VSeparator } from "components/separator/Separator";
import { pieChartOptions } from "variables/charts";

export default function Conversion(props) {
  const { ...rest } = props;

  const { order, data, percentage } = props?.chartdata;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card p="20px" align="center" direction="column" w="100%" {...rest}>
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        mb="8px"
      >
        <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
          User Applications Status
        </Text>
        {/* <Select
          fontSize="sm"
          variant="subtle"
          defaultValue="monthly"
          width="unset"
          fontWeight="700"
        >
          <option value="daily">Daily</option>
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option>
        </Select> */}
      </Flex>
      {data.length > 0 ? (
        <PieChart
          h="100%"
          w="100%"
          chartData={[...data]}
          chartOptions={pieChartOptions}
        />
      ) : (
        <Flex h="100%" />
      )}

      <Card
        bg={cardColor}
        flexDirection="row"
        boxShadow={cardShadow}
        w="100%"
        p="15px"
        px="20px"
        mt="15px"
        mx="auto"
      >
        <Flex direction="column" py="5px" width="100%" alignItems={"center"}>
          <Flex align="center">
            <Box h="8px" w="8px" bg="brand.500" borderRadius="50%" me="4px" />
            <Text
              fontSize="xs"
              color="secondaryGray.600"
              fontWeight="700"
              mb="5px"
            >
              {order[0]}
            </Text>
          </Flex>
          <Text fontSize="lg" color={textColor} fontWeight="700">
            {percentage[0]}%
          </Text>
        </Flex>
        <VSeparator mx={{ base: "60px", xl: "60px", "2xl": "60px" }} />
        <Flex
          direction="column"
          py="5px"
          me="10px"
          width="100%"
          alignItems={"center"}
        >
          <Flex align="center">
            <Box h="8px" w="8px" bg="#6AD2FF" borderRadius="50%" me="4px" />
            <Text
              fontSize="xs"
              color="secondaryGray.600"
              fontWeight="700"
              mb="5px"
            >
              {order[1]}
            </Text>
          </Flex>
          <Text fontSize="lg" color={textColor} fontWeight="700">
            {percentage[1]}%
          </Text>
        </Flex>
      </Card>
    </Card>
  );
}
