import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import {
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  EditableTextarea,
  Flex,
  IconButton,
  Input,
  Text,
  Textarea,
  useEditableControls,
} from "@chakra-ui/react";
import { useRef } from "react";

const CustomEditable = ({ label, value, onSubmit, isTextArea, setEditing }) => {
  const scrollRef = useRef(null);

  const EditableControls = () => {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup
        justifyContent="center"
        size="sm"
        ml={3}
        alignItems="center"
        isPreviewFocusable={true}
      >
        <IconButton icon={<CheckIcon />} {...getSubmitButtonProps()} />
        <IconButton icon={<CloseIcon />} {...getCancelButtonProps()} />
      </ButtonGroup>
    ) : (
      <Flex justifyContent="center" my={3}>
        <IconButton size="sm" icon={<EditIcon />} {...getEditButtonProps()} />
      </Flex>
    );
  };

  return (
    <>
      <Text mt={2} fontWeight="medium">
        {label}
      </Text>
      <Editable
        defaultValue={value}
        fontSize="md"
        borderWidth={0.5}
        px={3}
        borderRadius={10}
        mt={2}
        onSubmit={onSubmit}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        submitOnBlur={false}
      >
        <EditablePreview />

        {isTextArea ? (
          <Textarea as={EditableTextarea} size="md" focusBorderColor={null} />
        ) : (
          <Input
            as={EditableInput}
            size="md"
            focusBorderColor={null}
            type="number"
          />
        )}

        <div ref={scrollRef} />

        <EditableControls />
      </Editable>
    </>
  );
};

export default CustomEditable;
