import { EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import CustomEditable from "components/customEditable";
import { useAuth } from "contexts/AuthContext";
import { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAgentApplicationResponseFields } from "services/agents.service";
import {
  getUserByEmail_Optimized,
  getUserById,
  updateUserFields,
} from "services/applications.service";
import { setAgents } from "store/reducers/agentSlice";
import { USER_TYPES } from "utils/constants";
import wordsToNumbers from "words-to-numbers";

const ApplicationDetails = ({
  closeModal,
  isOpen,
  selectedUser,
  applicationData,
}) => {
  const [isAgentEditing, setIsAgentEditing] = useState(false);
  const [agentEmail, setAgentEmail] = useState("no agent assigned");
  const [agentUpdateLoading, setAgentUpdateLoading] = useState(false);

  const [userAgent, setUserAgent] = useState(null);
  const { agents } = useSelector((state) => state.agent);
  const { searchResults, setSearchresults } = useAuth();

  const dispatch = useDispatch();
  const toast = useToast();

  const displayData = useMemo(() => {
    let data = [];

    if (applicationData) {
      for (let key of Object.keys(applicationData)) {
        const name = key.split("step")[1];

        if (name) {
          let stepName = wordsToNumbers(name);
          let displayName = `Step ${stepName}`;
          let questions = applicationData[key];

          data.push({
            name: name,
            displayName: displayName,
            data: questions,
            step: stepName,
          });
        }
      }

      const finalData = data.sort((a, b) => a.step - b.step);

      return finalData;
    } else {
      return [];
    }
  }, [applicationData]);

  const fetchUserAgentDetails = async () => {
    try {
      if (selectedUser.agent) {
        const agent = await getUserById(selectedUser.agent);

        if (agent) {
          setUserAgent(agent);
          setAgentEmail(agent.email);
        }
      }
    } catch (err) {
      toast({
        title: "Something went wrong",
        description:
          "Unable to fetch user agent details at the moment. Please try again",
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "bottom",
      });
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(email);
  };

  const handleAgentAssignment = async () => {
    if (!isValidEmail(agentEmail)) {
      toast({
        // title: "Failed",
        description: "invalid email address",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "bottom",
      });

      return;
    }
    setAgentUpdateLoading(true);
    try {
      const agentDetails = await getUserByEmail_Optimized(
        agentEmail?.trim().toLowerCase()
      );

      if (!agentDetails) {
        toast({
          title: "Failed",
          description: "No user found with given email address",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "bottom",
        });
        setAgentUpdateLoading(false);

        return;
      }

      if (agentDetails.type !== USER_TYPES.AGENT) {
        toast({
          title: "Failed",
          description: "Provided user is not an agent",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "bottom",
        });
        setAgentUpdateLoading(false);

        return;
      }

      if (agentDetails.email === selectedUser.email) {
        toast({
          title: "Failed",
          description: "Cannot assign the agent to himself",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "bottom",
        });
        setAgentUpdateLoading(false);
        return;
      }

      await updateUserFields(
        {
          agent: agentDetails?.id,
        },
        applicationData?.uid
      );

      const agentIndex = agents.findIndex(
        (agent) => agent.id === applicationData.id
      );

      const updated = [...agents];

      updated[agentIndex] = {
        ...updated[agentIndex],
        agent: agentDetails?.id,
      };

      if (searchResults) {
        const searchIndex = searchResults.findIndex(
          (agent) => agent.id === applicationData.id
        );

        const updateSearch = [...searchResults];

        updateSearch[searchIndex] = {
          ...updateSearch[searchIndex],
          agent: agentDetails?.id,
        };
        setSearchresults(updateSearch);
      }

      dispatch(setAgents(updated));
      setUserAgent(agentDetails);
      setAgentEmail(agentDetails.email);

      setIsAgentEditing(false);
      setAgentUpdateLoading(false);

      toast({
        title: "Success",
        description: "Agent Assigned Successfully",
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "bottom",
      });
    } catch (err) {
      setAgentUpdateLoading(false);

      toast({
        title: "Failed",
        description: "Something went wrong while assigning agent",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "bottom",
      });
    }
  };

  useEffect(() => {
    if (selectedUser) {
      setAgentEmail("no agent assigned");
      setUserAgent(null);

      fetchUserAgentDetails();
    }

    console.log("---sjasvdhj--");
  }, [selectedUser]);

  return (
    <Modal
      isCentered
      size="full"
      onClose={closeModal}
      isOpen={isOpen}
      motionPreset="scale"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontSize={"2xl"}>
            {selectedUser?.legalName?.firstName}{" "}
            {selectedUser?.legalName?.lastName}
          </Text>
          <Text fontWeight={"medium"} fontSize="lg">
            {selectedUser?.email}
          </Text>

          {applicationData && (
            <Text
              fontWeight={"medium"}
              fontSize="lg"
              color={
                selectedUser?.applicationStatus === "Completed"
                  ? "green.400"
                  : "red.500"
              }
            >
              {selectedUser?.applicationStatus}
            </Text>
          )}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody scrollBehavior="smooth">
          <Heading fontSize="22px">Agent Information</Heading>

          <>
            <Card
              style={
                !applicationData
                  ? {
                      height: "78vh",
                      alignItems: "center",
                      justifyContent: "center",
                    }
                  : {}
              }
            >
              <>
                {applicationData ? (
                  <>
                    {displayData.map((step, index) => {
                      return (
                        <>
                          <Box key={index}>
                            <Text fontWeight="bold" fontSize="20px" my={"10px"}>
                              {step.displayName}
                            </Text>

                            <SimpleGrid
                              columns={2}
                              spacing={0}
                              backgroundColor="rgba(0,0,0,0.1)"
                            >
                              {step.data?.map((data, index) => {
                                return (
                                  <Box
                                    key={index}
                                    padding={"3"}
                                    borderWidth={1}
                                    borderColor="white"
                                  >
                                    <Text fontWeight="semibold">
                                      Question {index + 1}: {data.question}
                                    </Text>
                                    <Text fontWeight="thin">
                                      {" "}
                                      - {data.answer}
                                    </Text>
                                  </Box>
                                );
                              })}
                            </SimpleGrid>
                          </Box>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <Text
                      fontWeight={"bold"}
                      color={"red.300"}
                      style={{
                        fontSize: "22px",
                      }}
                    >
                      No application found against this user
                    </Text>
                  </>
                )}
              </>
            </Card>
          </>

          {applicationData && (
            <>
              <Heading fontSize="22px">Application Managment</Heading>

              <CustomEditable
                label="Notes"
                value={applicationData?.note}
                isTextArea
                onSubmit={(value) => {
                  updateAgentApplicationResponseFields(
                    {
                      note: value,
                    },
                    applicationData?.uid
                  );
                }}
              />
            </>
          )}

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt={4}
          >
            <Heading fontSize="22px">Referring Agent</Heading>

            <Box>
              <IconButton
                icon={<EditIcon />}
                title="Edit"
                onClick={() => setIsAgentEditing(true)}
              />
            </Box>
          </Box>

          <Text my={2} fontWeight="medium">
            Agent Email
          </Text>
          <Input
            value={agentEmail}
            onChange={(e) => {
              setAgentEmail(e.target.value);
            }}
            isReadOnly={!isAgentEditing}
          />

          {isAgentEditing && (
            <ButtonGroup
              mt={10}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                colorScheme="blue"
                px={10}
                onClick={handleAgentAssignment}
              >
                {agentUpdateLoading ? <Spinner /> : "Update"}
              </Button>

              <Button
                colorScheme="red"
                px={10}
                onClick={() => {
                  setIsAgentEditing(false);

                  setAgentEmail(
                    userAgent ? userAgent.email : "no agent assigned"
                  );
                }}
              >
                Cancel
              </Button>
            </ButtonGroup>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={closeModal}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default memo(ApplicationDetails);
