import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	users: [],
	agents: [],
	offers: [],
	applicationResponses: [],
	homebuyerApplicationResponses: [],
	coapplicantApplicationResponses: [],
	agentApplicationResponses: [],
	agentInvites: [],
};

export const DataSlice = createSlice({
	name: 'Data',
	initialState,
	reducers: {
		resetDataState: (state) => initialState,
		setUsers: (state, action) => {
			state.users = action.payload;
		},
		setAgents: (state, action) => {
			state.agents = action.payload;
		},
		setOffers: (state, action) => {
			state.offers = action.payload;
		},
		setApplicationResponses: (state, action) => {
			state.applicationResponses = action.payload;
		},
		setHomebuyerApplicationResponses: (state, action) => {
			state.homebuyerApplicationResponses = action.payload;
		},
		setCoapplicantApplicationResponses: (state, action) => {
			state.coapplicantApplicationResponses = action.payload;
		},
		setAgentApplicationResponses: (state, action) => {
			state.agentApplicationResponses = action.payload;
		},
		setAgentInvites: (state, action) => {
			state.agentInvites = action.payload;
		},
	},
});

export const {
	resetOfferState,
	setUsers,
	setAgents,
	setOffers,
	setApplicationResponses,
	setHomebuyerApplicationResponses,
	setCoapplicantApplicationResponses,
	setAgentApplicationResponses,
	setAgentInvites,
} = DataSlice.actions;

export default DataSlice.reducer;
