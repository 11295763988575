import { EditIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	IconButton,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SimpleGrid,
	Switch,
	Text,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import UpdateApplicationConfirmation from 'components/updateApplicationConfirmation';
import { Fragment, useEffect, useState } from 'react';
import {
	getUserApplication,
	updateApplicationResponseFields,
	updateApplicationWithSubmissions,
	updateCoApplicationResponseFields,
} from 'services/applications.service';

const ApplicationTab = ({
	applicationData,
	closeModal,
	displayData,
	coApplicantDetails,
	applicationDetails,
	setApplicationDetails,
	selectedUser,
	setCoApplicantDetails,
	originalCoapplicantData,
	coapplicantApplication,
}) => {
	const [changelog, setChangelog] = useState([]);
	const [coapplicantChangelog, setCoapplicantChangelog] = useState(
		[]
	);

	const [updatedFields, setUpdatedFields] = useState([]);
	const [coAppdatedFields, setcoAppUpdatedFields] = useState([]);

	const [updateLoading, setUpdateLoading] = useState(false);
	const [modalLoading, setModalLoading] = useState(false);
	const [isApplicationEditing, setIsApplicationEditing] =
		useState(false);
	const [isCoapplicationEditing, setIsCoapplicationEditing] =
		useState(false);
	const {
		isOpen: confirmationOpen,
		onOpen,
		onClose,
	} = useDisclosure();
	const {
		isOpen: coappConfirmationOpen,
		onOpen: onOpenCoapp,
		onClose: onCloseCoapp,
	} = useDisclosure();

	const [provideSubmissions, setProvideSubmissions] = useState(false);

	const {
		isOpen: submissionsIsOpen,
		onOpen: submissionsOnOpen,
		onClose: submissionsOnClose,
	} = useDisclosure();

	const toast = useToast();

	useEffect(() => {
		if (applicationData) {
			const fetchData = async () => {
				try {
					const userData = await getUserApplication(
						applicationData?.uid
					);
					setProvideSubmissions(userData.addCoBuyerAfterSubmission);
				} catch (err) {
					console.log('useEffect Error: ', err);
				}
			};

			fetchData().catch(console.error);
		}
	}, [applicationData]);

	const handleUpdateChangeLog = () => {
		let changedFields = [];

		let updated = [];

		for (let i = 0; i < displayData.length; i++) {
			for (let dataIndex in displayData[i].data) {
				if (
					applicationDetails[i]?.data[dataIndex]?.answer !==
					displayData[i].data[dataIndex]?.answer
				) {
					changedFields.push({
						previous: displayData[i].data[dataIndex],
						new: applicationDetails[i].data[dataIndex],
					});

					updated.push(applicationDetails[i]);
				}
			}
		}

		if (updated.length === 0) {
			return;
		}

		setUpdatedFields(updated);
		createApplicationUpdatePayload(updated);
		onOpen();
		setChangelog(changedFields);
	};

	const handleCoapplicantChangelog = () => {
		let changedFields = [];

		let updated = [];

		for (let i = 0; i < coApplicantDetails.length; i++) {
			for (let dataIndex in coApplicantDetails[i].data) {
				if (
					originalCoapplicantData[i]?.data[dataIndex]?.answer !==
					coApplicantDetails[i].data[dataIndex]?.answer
				) {
					changedFields.push({
						new: coApplicantDetails[i].data[dataIndex],
						previous: originalCoapplicantData[i].data[dataIndex],
					});

					updated.push(coApplicantDetails[i]);
				}
			}
		}

		if (updated.length === 0) {
			return;
		}

		setcoAppUpdatedFields(updated);
		createApplicationUpdatePayload(updated);
		onOpenCoapp();
		setCoapplicantChangelog(changedFields);
	};

	const handleApplicationUpdate = async () => {
		try {
			setIsApplicationEditing(false);
			setUpdateLoading(true);

			const payload = createApplicationUpdatePayload(updatedFields);

			await updateApplicationResponseFields(
				payload,
				applicationData?.uid
			);

			onClose();
			setUpdateLoading(false);
			toast({
				title: 'Success',
				description: 'Application updated successfully',
				status: 'success',
				duration: 4000,
				isClosable: true,
				position: 'bottom',
			});
			closeModal();
		} catch (err) {
			toast({
				title: 'Failed',
				description:
					'Something went wrong while updating application fields',
				status: 'error',
				duration: 4000,
				isClosable: true,
				position: 'bottom',
			});
		}
	};

	const handleCobuyerApplicationUpdate = async () => {
		try {
			setIsCoapplicationEditing(false);
			setUpdateLoading(true);

			const payload =
				createApplicationUpdatePayload(coAppdatedFields);

			await updateCoApplicationResponseFields(
				payload,
				coapplicantApplication?.id
			);

			// onClose();
			// setUpdateLoading(false);
			toast({
				title: 'Success',
				description: 'Application updated successfully',
				status: 'success',
				duration: 4000,
				isClosable: true,
				position: 'bottom',
			});
			closeModal();
		} catch (err) {
			toast({
				title: 'Failed',
				description:
					'Something went wrong while updating application fields',
				status: 'error',
				duration: 4000,
				isClosable: true,
				position: 'bottom',
			});
		} finally {
			onCloseCoapp();
			setUpdateLoading(false);
		}
	};

	const createApplicationUpdatePayload = (updatedFields) => {
		let payload = {};

		for (let updated of updatedFields) {
			const stepName = `step${updated.name}`;
			payload[stepName] = updated.data;
		}

		return payload;
	};

	const handleFieldEditing = (step, questionIndex, value) => {
		setApplicationDetails((prevDetails) => {
			const updatedResults = [...prevDetails];
			const updatedStep = { ...updatedResults[step] };
			const stepQuestions = [...updatedStep.data];
			const updatedQuestion = {
				...stepQuestions[questionIndex],
				answer: value,
			};
			stepQuestions[questionIndex] = updatedQuestion;
			updatedStep.data = stepQuestions;
			updatedResults[step] = updatedStep;
			return updatedResults;
		});
	};

	const handleCoaplicantFieldEditing = (
		step,
		questionIndex,
		value
	) => {
		setCoApplicantDetails((prevDetails) => {
			const updatedResults = [...prevDetails];
			const updatedStep = { ...updatedResults[step] };
			const stepQuestions = [...updatedStep.data];
			const updatedQuestion = {
				...stepQuestions[questionIndex],
				answer: value,
			};
			stepQuestions[questionIndex] = updatedQuestion;
			updatedStep.data = stepQuestions;
			updatedResults[step] = updatedStep;
			return updatedResults;
		});
	};

	const onCancel = () => {
		setApplicationDetails(displayData);
		setIsApplicationEditing(false);
	};

	const onCoappcancel = () => {
		setCoApplicantDetails(coApplicantDetails);
		setIsCoapplicationEditing(false);
	};

	const handleSubmissionUpdate = async () => {
		await updateApplicationWithSubmissions(applicationData.uid);
	};

	return (
		<Fragment>
			<Box>
				<Flex justifyContent={'space-between'}>
					{!!applicationData ? (
						<Box flex={1}>
							<Flex
								alignItems={'center'}
								justifyContent='space-between'
								marginRight={4}>
								<Heading fontSize='22px'>Buyer Information</Heading>

								{/* <Button
                  mx="4"
                  onClick={async () => {
                    await updateApplicationWithSubmissionsReset()
                  }}
                >
                  Turn
                </Button> */}

								<FormControl
									display='flex'
									alignItems='center'>
									<FormLabel
										htmlFor='email-alerts'
										mb='0'>
										Enable Co-Buyers After Submission
									</FormLabel>
									<Switch
										id='email-alerts'
										isChecked={provideSubmissions}
										onChange={async () => {
											if (provideSubmissions === true) {
												await handleSubmissionUpdate();
												setProvideSubmissions(!provideSubmissions);
											} else if (provideSubmissions === false) {
												submissionsOnOpen();
											}
										}}
									/>
								</FormControl>

								<Flex alignItems={'center'}>
									{isApplicationEditing ? (
										<>
											<Button
												colorScheme='blue'
												px={10}
												mr={2}
												onClick={handleUpdateChangeLog}>
												{'Update Application'}
											</Button>
											<Button
												colorScheme='red'
												// px={10}
												onClick={onCancel}>
												Cancel
											</Button>
										</>
									) : (
										<IconButton
											icon={<EditIcon />}
											title='Edit'
											onClick={() => setIsApplicationEditing(true)}
										/>
									)}
								</Flex>
							</Flex>

							{applicationDetails?.map((step, stepIndex) => {
								return (
									<>
										<Box key={stepIndex}>
											<Text
												fontWeight='bold'
												fontSize='20px'
												my={'10px'}>
												{step.displayName}
											</Text>

											<SimpleGrid
												columns={
													coApplicantDetails?.length > 0 ? 1 : 2
												}
												spacing={0}
												backgroundColor='rgba(0,0,0,0.1)'>
												{step.data?.map((data, index) => {
													// if (!data.answer) return;

													return (
														<Box
															key={index}
															padding={'3'}
															borderWidth={1}
															borderColor='white'
															height='11vh'>
															<Text fontWeight='semibold'>
																{data.question
																	? `Question ${index + 1}: ${
																			data.question
																	  }`
																	: ''}
															</Text>
															<Input
																padding={0}
																value={data.answer}
																defaultValue={data.answer}
																borderWidth={
																	isApplicationEditing ? 1 : 0
																}
																onChange={(e) => {
																	handleFieldEditing(
																		stepIndex,
																		index,
																		e.target.value
																	);
																}}
															/>
														</Box>
													);
												})}
											</SimpleGrid>
										</Box>
									</>
								);
							})}
						</Box>
					) : (
						<Box>
							<Text
								fontWeight={'bold'}
								color={'red.300'}
								style={{
									fontSize: '22px',
								}}>
								No application found against this user
							</Text>
						</Box>
					)}

					{coApplicantDetails?.length && (
						<>
							<Box
								borderWidth={1}
								mx={5}
							/>

							<Box maxW={'45vw'}>
								<Flex
									alignItems={'center'}
									justifyContent='space-between'
									marginRight={4}
									mb={6}>
									<Heading fontSize='22px'>
										Co-Buyer Information
									</Heading>

									<Flex alignItems={'center'}>
										{isCoapplicationEditing ? (
											<>
												<Button
													colorScheme='blue'
													px={10}
													mr={2}
													onClick={handleCoapplicantChangelog}>
													{'Update Application'}
												</Button>
												<Button
													colorScheme='red'
													// px={10}
													onClick={onCoappcancel}>
													Cancel
												</Button>
											</>
										) : (
											<IconButton
												icon={<EditIcon />}
												title='Edit'
												onClick={() =>
													setIsCoapplicationEditing(true)
												}
											/>
										)}
									</Flex>
								</Flex>

								{coApplicantDetails?.map((step, stepIndex) => {
									return (
										<>
											<Box key={stepIndex}>
												<Text
													fontWeight='bold'
													fontSize='20px'
													my={'10px'}>
													{step?.displayName}
												</Text>

												<SimpleGrid
													columns={1}
													spacing={0}
													backgroundColor='rgba(0,0,0,0.1)'>
													{step.data &&
														step.data?.map((data, index) => {
															return (
																<Box
																	key={index}
																	padding={'3'}
																	borderWidth={1}
																	borderColor='white'
																	height='11vh'>
																	<Text fontWeight='semibold'>
																		{data?.question
																			? `Question ${index + 1}: ${
																					data?.question
																			  } `
																			: ''}{' '}
																	</Text>
																	<Input
																		padding={0}
																		value={data?.answer}
																		defaultValue={data?.answer}
																		contentEditable={false}
																		borderWidth={
																			isApplicationEditing ? 1 : 0
																		}
																		onChange={(e) => {
																			handleCoaplicantFieldEditing(
																				stepIndex,
																				index,
																				e.target.value
																			);
																		}}
																	/>
																</Box>
															);
														})}
												</SimpleGrid>
											</Box>
										</>
									);
								})}
							</Box>
						</>
					)}
				</Flex>
				<UpdateApplicationConfirmation
					title='Application Update Confirmation'
					message='Hello'
					changelog={changelog}
					onClose={() => {
						setIsApplicationEditing(false);
						onCancel();
						onClose();
					}}
					isOpen={confirmationOpen}
					loading={updateLoading}
					onConfirm={() => {
						handleApplicationUpdate();
					}}
					selectedUser={selectedUser}
				/>

				<UpdateApplicationConfirmation
					title='Co-buyer Application Update Confirmation'
					message='Hello'
					changelog={coapplicantChangelog}
					onClose={() => {
						setIsCoapplicationEditing(false);
						onCoappcancel();
						onCloseCoapp();
					}}
					isOpen={coappConfirmationOpen}
					loading={updateLoading}
					onConfirm={() => {
						handleCobuyerApplicationUpdate();
					}}
					selectedUser={selectedUser}
				/>
			</Box>

			<Modal
				isOpen={submissionsIsOpen}
				onClose={submissionsOnClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Are you sure?</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Box>Are you sure?</Box>
					</ModalBody>

					<ModalFooter>
						<Button
							variant='ghost'
							onClick={submissionsOnClose}>
							Close
						</Button>
						<Button
							colorScheme='blue'
							mr={3}
							onClick={async () => {
								setModalLoading(true);
								await handleSubmissionUpdate();
								setProvideSubmissions(!provideSubmissions);
								submissionsOnClose();
								setModalLoading(false);
							}}
							isLoading={modalLoading}>
							{modalLoading ? 'Loading' : 'Update'}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Fragment>
	);
};
export default ApplicationTab;
