import { createContext, useContext, useState } from "react";

import {
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signOut,
} from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import app from "../utils/firebase";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const db = getFirestore(app);

  const [authUser, setAuthUser] = useState(null);
  const [authLoading, setAuthLoading] = useState(false);
  const [searchResults, setSearchresults] = useState(null);

  const handleLogIn = async (email, password) => {
    try {
      setAuthLoading(true);
      const auth = getAuth(app);
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      if (userCredential.user) {
        let user = userCredential.user;
        const docRef = doc(db, "users", userCredential?.user.uid);
        const docSnap = await getDoc(docRef);
        const dbUser = docSnap.data();

        setAuthLoading(false);

        if (dbUser) {
          if (dbUser.isAdmin) {
            setAuthUser({
              accessToken: user.accessToken,
              displayName: user.displayName,
              email: user.email,
              emailVerified: user.emailVerified,
              photoURL: user.photoURL,
              uid: user.uid,
              data: dbUser,
            });
            return { error: false };
          } else {
            return { error: "User is not admin" };
          }
        }
      }

      return { error: "Something went wrong" };
    } catch (err) {
      console.log("Error on handleLogIn: ", err);
      setAuthUser(null);
      setAuthLoading(false);

      if (err.code) {
        return { error: "Something went wrong: " + err.code };
      }
      return { error: "Something went wrong" };
    }
  };

  const handleGoogleLogIn = async () => {
    try {
      setAuthLoading(true);
      const provider = new GoogleAuthProvider();
      const auth = getAuth(app);
      const result = await signInWithRedirect(auth, provider);

      setAuthLoading(false);

      if (result.user) {
        return { error: false };
      }

      return { error: "Something went wrong" };
    } catch (err) {
      console.log("Error on handleGoogleLogIn: ", err);
      setAuthUser(null);
      setAuthLoading(false);

      if (err.code) {
        return { error: "Something went wrong: " + err.code };
      }
      return { error: "Something went wrong" };
    }
  };

  const handleSignOut = async () => {
    try {
      const auth = getAuth(app);
      setAuthLoading(true);
      await auth.signOut();
      await signOut(auth);

      setAuthUser(null);
      setAuthLoading(false);

      return {};
    } catch (err) {
      setAuthLoading(false);
    }
  };

  const value = {
    handleLogIn,
    handleGoogleLogIn,
    handleSignOut,
    authUser,
    authLoading,
    searchResults,
    setSearchresults,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
