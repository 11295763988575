import { Fragment, useEffect, useState } from 'react';
import { supabase } from '../../../utils/supabase';

// chakra
import {
	Box,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	TableCaption,
	TableContainer,
	Button,
	IconButton,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { set } from 'date-fns';

const WaitlistUsers = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const [waitlistUsers, setWaitlistUsers] = useState([]);
	const [deleteUser, setDeleteUser] = useState(null);

	const toast = useToast();

	useEffect(() => {
		const fetchData = async () => {
			const { data, error } = await supabase
				.from('waitlist_users')
				.select()
				.order('id', { ascending: false });

			setWaitlistUsers(data);
		};

		fetchData().catch(console.error);
	}, []);

	const convertDateTime = (dateTime) => {
		const date = new Date(dateTime);
		const year = date.getFullYear();
		const month = date.getMonth() + 1;
		const day = date.getDate();
		const hour = date.getHours();
		const minute = date.getMinutes();
		const second = date.getSeconds();
		const formattedDateTime = `${month}/${day}/${year}`;
		return formattedDateTime;
	};

	const handleDelete = async (id) => {
		try {
			await supabase.from('waitlist_users').delete().eq('id', id);

			const { data, error } = await supabase
				.from('waitlist_users')
				.select()
				.order('id', { ascending: false });

			setWaitlistUsers(data);
		} catch (err) {
			console.log(err);
			toast({
				title: 'Error',
				description: 'Something went wrong. Please try again later.',
				status: 'error',
				duration: 9000,
				isClosable: true,
				position: 'top-right',
			});
		}
	};

	return (
		<Fragment>
			<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
				<Box
					bg='white'
					rounded='lg'
					p='4'>
					<TableContainer>
						<Table variant='simple'>
							<Thead>
								<Tr>
									<Th>Email</Th>
									<Th>First Name</Th>
									<Th>Last Name</Th>
									<Th>Phone Number</Th>
									<Th>Created At</Th>
									<Th>Emailed</Th>
									<Th>Action</Th>
								</Tr>
							</Thead>
							<Tbody>
								{waitlistUsers.map((user) => (
									<Tr key={user.id}>
										<Td>{user?.email}</Td>
										<Td>{user?.first_name}</Td>
										<Td>{user?.last_name}</Td>
										<Td>{user?.phone_number}</Td>
										<Td>
											{user?.created_at
												? convertDateTime(user.created_at)
												: null}
										</Td>
										<Td>{user?.emailed ? 'True' : 'False'}</Td>
										<Td>
											<IconButton
												colorScheme='red'
												variant='outline'
												aria-label='Delete'
												icon={<DeleteIcon />}
												onClick={() => {
													setDeleteUser(user);
													onOpen();
												}}
											/>
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</TableContainer>
				</Box>
			</Box>

			<Modal
				isOpen={isOpen}
				onClose={() => {
					setDeleteUser(null);
					onClose();
				}}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader></ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Box>Are you sure you want to delete?</Box>
					</ModalBody>

					<ModalFooter>
						<Button
							variant='ghost'
							mr={3}
							onClick={() => {
								setDeleteUser(null);
								onClose();
							}}>
							Close
						</Button>
						<Button
							colorScheme='red'
							onClick={() => {
								handleDelete(deleteUser.id);
								setDeleteUser(null);
								onClose();
							}}>
							Delete
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Fragment>
	);
};

export default WaitlistUsers;
