import { Flex, Text, useColorModeValue } from "@chakra-ui/react";

import { UtopiaLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";

export const SidebarBrand = () => {
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align="center" direction="column">
      <UtopiaLogo h="40px" w="40px" mt="20px" color={logoColor} />
      <Text my="20px" fontWeight="bold">
        {" "}
        UTOPIA HOMES - DASHBOARD
      </Text>

      <HSeparator mb="20px" />
    </Flex>
  );
};

export default SidebarBrand;
