import {
	Box,
	Button,
	Flex,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import ConfirmationDialog from 'components/confirmationDialog';
import { DatePicker } from 'components/datePicker/DatePicker';
import { useAuth } from 'contexts/AuthContext';
import moment from 'moment-timezone';
import { useMemo, useState } from 'react';
import CsvDownloadButton from 'react-json-to-csv';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
	changeUserStatusToDeleted,
	getHomebuyerFilesById,
	getUserFilesById,
} from 'services/applications.service';
import { handleDeleteFromLocal } from 'utils/helpers';
import UsersList from './components/UsersList';

const Others = () => {
	const [selectedUser, setSelectedUser] = useState(null);

	const [applicationData, setApplicationData] = useState(null);
	const [storageData, setStorageData] = useState();
	const [loading, setLoading] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [dialogLoading, setDialogLoading] = useState(false);
	const [deleteId, setDeleteId] = useState(null);
	const [userName, setUserName] = useState('');

	const [filteredData, setFilteredData] = useState(null);
	const toast = useToast();

	const { searchResults, setSearchresults } = useAuth();

	const {
		allApplications,
		pendingApplications,
		approvedApplications,
		rejectedApplications,
		moreInfoRequestApplications,
		startedApplications,
		otheredApplications,
		inReviewApplications,
		pausedApplications,
		allSubmittedApplications,
		offerClosed,
		preclosingComplete,
		inspectionComplete,
		offerAccepted,
		offerExtended,
		conditionallyApproved,
		inactive,
	} = useSelector((state) => state.user);

	const route = useLocation();

	const [displayData, downloadData, downloadHeaders, currentRoute] =
		useMemo(() => {
			let temp = route?.pathname?.split('/');

			let currentRoute = temp[temp.length - 1];
			console.log(
				'🚀 ~ file: index.jsx:60 ~ useMemo ~ currentRoute:',
				currentRoute
			);

			let data = [];

			console.log({ currentRoute });

			if (currentRoute === 'approved') {
				data = approvedApplications;
			} else if (currentRoute === 'inbox') {
				data = pendingApplications;
			} else if (currentRoute === 'info-required') {
				data = moreInfoRequestApplications;
			} else if (currentRoute === 'rejected') {
				data = rejectedApplications;
			} else if (currentRoute === 'started') {
				data = startedApplications;
			} else if (currentRoute === 'in-review') {
				data = inReviewApplications;
			} else if (currentRoute === 'paused') {
				data = pausedApplications;
			} else if (currentRoute === 'all-submitted') {
				data = allSubmittedApplications;
			} else if (currentRoute === 'conditionally-approved') {
				data = conditionallyApproved;
			} else if (currentRoute === 'inspection-complete') {
				data = inspectionComplete;
			} else if (currentRoute === 'preclosing-complete') {
				data = preclosingComplete;
			} else if (currentRoute === 'offer-extended') {
				data = offerExtended;
			} else if (currentRoute === 'offer-accepted') {
				data = offerAccepted;
			} else if (currentRoute === 'offer-closed') {
				data = offerClosed;
			} else if (currentRoute === 'inactive') {
				data = inactive;
			} else if (currentRoute === 'othered') {
				data = otheredApplications;
			} else {
				data = allApplications;
			}

			let dataToDownload = filteredData ?? data;

			let downloadData = dataToDownload.map((val, index) => {
				const agentEmail = val.application?.stepFour
					? val.application?.stepFour[3].answer
					: '';

				const stepFive = val.application?.stepFive;
				const coApplicantName = stepFive
					? stepFive[1].answer + ' ' + stepFive[2].answer
					: '';

				const coApplicantEmail = stepFive ? stepFive[3].answer : '';

				const coApplicantPhone = stepFive
					? stepFive[4].answer?.toLocaleString('fullwide', {
							useGrouping: false,
					  })
					: '';

				return {
					'#': index,
					id: val.displayId,
					firstName: val.firstName,
					lastName: val.lastName,
					email: val.email,
					preQualifiedAmount: val.preQualifiedAmount,
					submissionDate: val.submissionDate,
					applicationStartDate: val.applicationStartDate,
					applicationStatus: val.applicationStatus,
					applicationCompleted: val.applicationCompleted,
					applicationStarted: val.applicationStarted,
					agent: agentEmail,
					coApplicantName,
					coApplicantEmail,
					coApplicantPhone,
					qualifiedAmount: val?.qualifiedAmount,
				};
			});

			const headers = [
				'#',
				'id',
				'firstName',
				'lastName',
				'email',
				'preQualifiedAmount',
				'submissionDate',
				'applicationStartDate',
				'applicationStatus',
				'applicationCompleted',
				'applicationStarted',
				'agent',
				'coApplicantName',
				'coApplicantEmail',
				'coApplicantPhone',
				'qualifiedAmount',
			];

			currentRoute += '-Applications';

			return [data, downloadData, headers, currentRoute];
		}, [
			route.pathname,
			allApplications,
			pendingApplications,
			approvedApplications,
			rejectedApplications,
			moreInfoRequestApplications,
			startedApplications,
			inReviewApplications,
			pausedApplications,
			allSubmittedApplications,
			offerClosed,
			preclosingComplete,
			inspectionComplete,
			offerAccepted,
			offerExtended,
			conditionallyApproved,
			inactive,
			filteredData,
		]);

	const getStorageData = async (userId) => {
		try {
			setLoading(true);
			let storageRes = await getUserFilesById(userId);

			if (
				storageRes.backgroundVerification?.length === 0 &&
				storageRes.bankStatements?.length === 0 &&
				storageRes.incomeVerification.length === 0
			) {
				storageRes = await getHomebuyerFilesById(userId);
			}

			setStorageData(storageRes);
			setLoading(false);
		} catch (err) {
			setLoading(false);

			toast({
				title: 'Error',
				description:
					'Something went wrong while getting user files. Please try again',
				status: 'error',
				duration: 4000,
				isClosable: true,
				position: 'top-right',
			});
		}
	};

	const handleConfirmationOpen = (userId, name) => {
		setUserName(name);
		setDeleteId(userId);
		onOpen();
	};

	const handleDelete = async () => {
		try {
			setDialogLoading(true);

			await changeUserStatusToDeleted(deleteId);
			if (searchResults) {
				const filter = searchResults.filter(
					(invite) => invite.id !== deleteId
				);
				setSearchresults(filter);
			}

			handleDeleteFromLocal(allApplications, deleteId);
			setDeleteId(null);
			setDialogLoading(false);
			onClose();
			toast({
				title: 'Success',
				description: 'User deleted successfully',
				status: 'success',
				duration: 4000,
				isClosable: true,
				position: 'top-right',
			});
		} catch (err) {
			setDeleteId(null);
			setDialogLoading(false);
			onClose();

			toast({
				title: 'Error',
				description:
					'Unable to delete application at the moment. Please try again later',
				status: 'error',
				duration: 4000,
				isClosable: true,
				position: 'top-right',
			});
		}
	};

	const handleDateFilter = (values) => {
		let temp = route?.pathname?.split('/');
		let currentRoute = temp[temp.length - 1];

		if (values?.start && values?.end) {
			const startDate = new Date(values.start);
			const endDate = new Date(values.end);
			endDate.setHours(23, 59, 59, 999);

			const response = displayData?.filter((item) => {
				let dateKey = item.submissionDate;
				if (currentRoute === 'started' || currentRoute === 'all') {
					dateKey = item.applicationStartDate;
				}

				const submissionDate = new Date(dateKey);

				// Convert submissionDate, startDate, and endDate to UTC before comparison
				const submissionDateUTC = Date.UTC(
					submissionDate.getFullYear(),
					submissionDate.getMonth(),
					submissionDate.getDate()
				);
				const startDateUTC = Date.UTC(
					startDate.getFullYear(),
					startDate.getMonth(),
					startDate.getDate()
				);
				const endDateUTC = Date.UTC(
					endDate.getFullYear(),
					endDate.getMonth(),
					endDate.getDate()
				);

				return (
					submissionDateUTC >= startDateUTC &&
					submissionDateUTC <= endDateUTC
				);
			});

			setFilteredData(response);
		}
	};

	console.log({ searchResults, filteredData, displayData });

	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<ConfirmationDialog
				title='Delete Application'
				message={`Are you sure you want to delete <b>${
					userName ?? ''
				}</b> application?`}
				isOpen={isOpen}
				onOpen={onOpen}
				onClose={onClose}
				loading={dialogLoading}
				onConfirm={() => handleDelete()}
			/>
			<Flex
				justifyContent={'space-between'}
				mb={2}>
				<Button>
					<CsvDownloadButton
						data={downloadData}
						filename={`${currentRoute}-Export-${moment(new Date())
							.tz('America/New_York')
							.format('DD/MM/YY')}`}
						delimiter=','
						headers={downloadHeaders}
					/>
				</Button>

				<DatePicker
					closeAction={handleDateFilter}
					clearAction={() => {
						setFilteredData(null);
					}}
				/>
			</Flex>

			<UsersList
				data={
					searchResults
						? searchResults
						: filteredData
						? filteredData
						: displayData
				}
				setSelectedUser={setSelectedUser}
				storageData={storageData}
				setStorageData={setStorageData}
				selectedUser={selectedUser}
				loading={loading}
				applicationData={applicationData}
				setApplicationData={setApplicationData}
				getStorageData={getStorageData}
				handleDelete={handleConfirmationOpen}
				displayData={displayData}
			/>
		</Box>
	);
};

export default Others;
