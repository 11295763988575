import { Button, Text } from "@chakra-ui/react";
import CustomTable from "components/customTable";
import moment from "moment-timezone";
import React from "react";
import OfferDetails from "./OfferDetails";
import { DeleteIcon } from "@chakra-ui/icons";
import ApplicationDetails from "views/admin/users/components/ApplicationDetails";
import { useSelector } from "react-redux";

const OffersList = (props) => {
  const {
    data,
    setSelectedUser,
    selectedUser,
    loading,
    getStorageData,
    applicationData,
    storageData,
    setStorageData,
    setApplicationData,
    handleDelete,
    userLoading,
    handleSelectRow,
    selectionLoading,
  } = props;

  const { allApplications } = useSelector((state) => state.user);

  const closeModal = () => {
    setSelectedUser(null);
  };

  const columns = React.useMemo(
    () => [
      {
        header: () => <span>#</span>,
        accessorKey: "serialNo",
        enableSorting: false,
        cell: (row) => {
          return <div>{row?.row?.index + 1}</div>;
        },
      },
      {
        accessorKey: "agentName",
        header: () => <span>Agent Name</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "agentEmail",
        header: () => <span>Agent Email</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "clientName",
        header: () => <span>Client Name</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "clientEmail",
        header: () => <span>Client Email</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "propertyAddress",
        header: () => <span>Property Address</span>,
        footer: (props) => props.column.id,
        cell: (row) => {
          let value = row.getValue();
          return (
            <Text maxW={"8vw"} wordBreak="break-word" isTruncated>
              {value}
            </Text>
          );
        },
      },
      {
        accessorKey: "propertyListPrice",
        header: () => <span>List Price</span>,
        footer: (props) => props.column.id,
        sortType: "number",
        cell: (row) => {
          let value = row.getValue();
          return <Text>{value?.toLocaleString()}</Text>;
        },
      },
      {
        accessorKey: "propertyOfferPrice",
        header: () => <span>Offer Price</span>,
        footer: (props) => props.column.id,
        sortType: "number",
        cell: (row) => {
          let value = row.getValue();
          return <Text>{value?.toLocaleString()}</Text>;
        },
      },

      {
        accessorKey: "timeSubmitted",
        header: () => <span>Created At</span>,
        footer: (props) => props.column.id,
        sortType: "datetime",
        cell: (row) => {
          let value = row.getValue();
          value = value?.toDate();
          return (
            <Text>
              {value
                ? moment(value)
                    .tz("America/New_York")
                    .format("DD MMM YYYY,  hh:mm a")
                : ""}
            </Text>
          );
        },
      },

      {
        accessorKey: "actions",
        header: () => <span>Actions</span>,
        footer: (props) => props.column.id,
        cell: (row) => {
          return (
            <Button width={"100%"} maxH={"25px"} background="transparent">
              <DeleteIcon color="red.400" />
            </Button>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <CustomTable
        data={data}
        columns={columns}
        onRowPress={handleSelectRow}
        loading={userLoading}
        onActionPress={(data) => {
          const name = data?.clientName + " " + data?.clientName;
          handleDelete(data.uid, name);
        }}
      />

      {/* <OfferDetails
        closeModal={closeModal}
        data={selectedUser}
        isOpen={Boolean(selectedUser)}
        loading={loading}
      /> */}

      <ApplicationDetails
        closeModal={closeModal}
        selectedUser={selectedUser}
        isOpen={Boolean(selectedUser)}
        loading={loading}
        applicationData={applicationData}
        storageData={storageData}
        setSelectedUser={setSelectedUser}
        setApplicationData={setApplicationData}
        setStorageData={setStorageData}
        allAplications={allApplications}
        getStorageData={getStorageData}
      />
    </>
  );
};

export default OffersList;
