import {
	Box,
	SimpleGrid,
	Spinner,
	Text,
	useColorModeValue,
	useToast,
} from '@chakra-ui/react';
// Assets
// Custom components
import MiniStatistics from 'components/card/MiniStatistics';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	getGraphsDataBasedOnDateRange,
	getPieChartStats,
	getPieChartStatsByDateRange,
} from 'services/applications.service';
import PieCard from 'views/admin/dashboardMain/components/PieCard';
import BarWrapper from './components/BarChart';
import DateFilter from './components/DateFilter';

import { DateTime } from 'luxon';
import { useTimezone } from '../../../contexts/TimezoneContext';

const UserReports = () => {
	const timezone = useTimezone();
	const now = DateTime.now().setZone(timezone);

	console.log({ date: now.toString() });

	const [loading, setLoading] = useState(false);
	const [pieChartData, setPieChartData] = useState({
		order: [],
		data: [],
		percentage: [],
		total: 0,
	});
	const [pieChartDataByDateRange, setPieChartDataByDateRange] =
		useState({
			order: [],
			data: [],
			percentage: [],
			total: 0,
		});

	const {
		completeApplicationBarData,
		startedApplicationBarData,
		monthlyCompletedApplications,
		monthlyStartedApplications,
		monthlyOffersExtended,
		monthlyOffersAccepted,
		monthlyInspectionsCompleted,
		monthlyPreclosingsCompleted,
		monthlyOffersClosed,
		monthlyAgentJoinees,
		monthlyAgentAgentInvites,
		monthlyAgentClientInvites,
		monthlyClientClientInvites,
	} = useSelector((state) => state.graph);
	const {
		allApplications,
		pendingApplications,
		approvedApplications,
		rejectedApplications,
		moreInfoRequestApplications,
		startedApplications,
		inReviewApplications,
		pausedApplications,
		allSubmittedApplications,
		offerClosed,
		preclosingComplete,
		inspectionComplete,
		offerAccepted,
		offerExtended,
		conditionallyApproved,
		inactive,
		dateRange_allApplications,
		dateRange_allSubmittedApplications,
		dateRange_startedApplications,
		dateRange_offerExtended,
		dateRange_offerAccepted,
		dateRange_inspectionComplete,
		dateRange_preclosingComplete,
		dateRange_offerClosed,
	} = useSelector((state) => state.user);
	const {
		agents,
		agentInvites,
		clientInvites,
		agentClientInvites,
		dateRange_agents,
		dateRange_agentInvites,
		dateRange_clientInvites,
		dateRange_agentClientInvites,
	} = useSelector((state) => state.agent);

	const textColor = useColorModeValue('secondaryGray.900', 'white');

	const toast = useToast();

	const getChartsData = async () => {
		try {
			const data = await getPieChartStats();
			const data2 = await getPieChartStatsByDateRange();

			setPieChartData(data);
			setPieChartDataByDateRange(data2);
		} catch (err) {
			toast({
				title: 'Error',
				description:
					'Something went wrong while getting charts data. Please try again later',
				status: 'error',
				duration: 4000,
				isClosable: true,
				position: 'top-right',
			});
			setLoading(false);
		}
	};

	const handleLoading = (val) => setLoading(val);

	useEffect(() => {
		getChartsData();
		getGraphsDataBasedOnDateRange(() => {
			setLoading(false);
		});
	}, []);

	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<DateFilter handleLoading={handleLoading} />

			{loading ? (
				<Box
					display={'flex'}
					alignItems='center'
					justifyContent='center'
					h='60vh'>
					<Spinner size={'lg'} />
				</Box>
			) : (
				<>
					<Text
						color={textColor}
						fontSize='2xl'
						fontWeight='bold'
						my={4}>
						Live to Date Metrics
					</Text>
					<SimpleGrid
						columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
						gap='20px'
						mb='20px'>
						<MiniStatistics
							name='Total Users'
							value={allApplications.length}
						/>
						<MiniStatistics
							name='Submitted Applications'
							value={allSubmittedApplications.length}
						/>
						<MiniStatistics
							name='Incomplete/Not submitted Applications'
							value={startedApplications.length}
						/>
						<MiniStatistics
							name='Offers Extended'
							value={offerExtended.length}
						/>
						<MiniStatistics
							name='Offers Accepted'
							value={offerAccepted.length}
						/>
						<MiniStatistics
							name='Inspections Completed'
							value={inspectionComplete.length}
						/>
						<MiniStatistics
							name='Preclosings Completed'
							value={preclosingComplete.length}
						/>
						<MiniStatistics
							name='Offers Closed'
							value={offerClosed.length}
						/>
						<MiniStatistics
							name='Agent Joinees'
							value={agents.length}
						/>
						<MiniStatistics
							name='Agent-Agent Invites'
							value={agentInvites.length}
						/>
						<MiniStatistics
							name='Agent Client Invites'
							value={agentClientInvites.length}
						/>
						<MiniStatistics
							name='Client Client Invites'
							value={clientInvites.length}
						/>
					</SimpleGrid>
					<Text
						color={textColor}
						fontSize='2xl'
						fontWeight='bold'
						my={4}>
						By Date Range Metrics
					</Text>
					<SimpleGrid
						columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
						gap='20px'
						mb='20px'>
						<MiniStatistics
							name='Total Users'
							value={dateRange_allApplications.length}
						/>
						<MiniStatistics
							name='Submitted Applications'
							value={dateRange_allSubmittedApplications.length}
						/>
						<MiniStatistics
							name='Incomplete/Not submitted Applications'
							value={dateRange_startedApplications.length}
						/>
						<MiniStatistics
							name='Offers Extended'
							value={dateRange_offerExtended.length}
						/>
						<MiniStatistics
							name='Offers Accepted'
							value={dateRange_offerAccepted.length}
						/>
						<MiniStatistics
							name='Inspections Completed'
							value={dateRange_inspectionComplete.length}
						/>
						<MiniStatistics
							name='Preclosings Completed'
							value={dateRange_preclosingComplete.length}
						/>
						<MiniStatistics
							name='Offers Closed'
							value={dateRange_offerClosed.length}
						/>
						<MiniStatistics
							name='Agent Joinees'
							value={dateRange_agents.length}
						/>
						<MiniStatistics
							name='Agent-Agent Invites'
							value={dateRange_agentInvites.length}
						/>
						<MiniStatistics
							name='Agent Client Invites'
							value={dateRange_agentClientInvites.length}
						/>
						<MiniStatistics
							name='Client Client Invites'
							value={dateRange_clientInvites.length}
						/>
					</SimpleGrid>
					<Text
						color={textColor}
						fontSize='2xl'
						fontWeight='bold'
						my={4}>
						Daily Stats
					</Text>
					<SimpleGrid
						minH={'45vh'}
						columns={{ base: 1, md: 1, xl: 1 }}
						mb='20px'>
						<BarWrapper
							data={startedApplicationBarData}
							title='Applications Started Per Day'
						/>
					</SimpleGrid>
					<SimpleGrid
						minH={'45vh'}
						columns={{ base: 1, md: 1, xl: 1 }}
						mb='20px'>
						<BarWrapper
							data={completeApplicationBarData}
							title='Applications Submitted Per Day'
						/>
					</SimpleGrid>
					<Text
						color={textColor}
						fontSize='2xl'
						fontWeight='bold'
						my={4}>
						Monthly Stats
					</Text>
					<SimpleGrid
						minH={'45vh'}
						columns={{ base: 1, md: 1, xl: 1 }}
						mb='20px'>
						<BarWrapper
							data={monthlyStartedApplications}
							title='Applications Started Per Month'
						/>
					</SimpleGrid>
					<SimpleGrid
						minH={'45vh'}
						columns={{ base: 1, md: 1, xl: 1 }}
						mb='20px'>
						<BarWrapper
							data={monthlyCompletedApplications}
							title='Applications Completed Per Month'
						/>
					</SimpleGrid>
					<SimpleGrid
						minH={'45vh'}
						columns={{ base: 1, md: 1, xl: 1 }}
						mb='20px'>
						<BarWrapper
							data={monthlyOffersExtended}
							title='Offers Extended Per Month'
						/>
					</SimpleGrid>
					<SimpleGrid
						minH={'45vh'}
						columns={{ base: 1, md: 1, xl: 1 }}
						mb='20px'>
						<BarWrapper
							data={monthlyOffersAccepted}
							title='Offers Accepted Per Month'
						/>
					</SimpleGrid>
					<SimpleGrid
						minH={'45vh'}
						columns={{ base: 1, md: 1, xl: 1 }}
						mb='20px'>
						<BarWrapper
							data={monthlyInspectionsCompleted}
							title='Inspections Completed Per Month'
						/>
					</SimpleGrid>
					<SimpleGrid
						minH={'45vh'}
						columns={{ base: 1, md: 1, xl: 1 }}
						mb='20px'>
						<BarWrapper
							data={monthlyPreclosingsCompleted}
							title='Preclosings Completed Per Month'
						/>
					</SimpleGrid>
					{/* <SimpleGrid
						minH={'45vh'}
						columns={{ base: 1, md: 1, xl: 1 }}
						mb='20px'>
						<BarWrapper
							data={monthlyOffersClosed}
							title='Offers Closed Per Month'
						/>
					</SimpleGrid> */}
					<SimpleGrid
						minH={'45vh'}
						columns={{ base: 1, md: 1, xl: 1 }}
						mb='20px'>
						<BarWrapper
							data={monthlyAgentJoinees}
							title='Agent Joinees Per Month'
						/>
					</SimpleGrid>
					<SimpleGrid
						minH={'45vh'}
						columns={{ base: 1, md: 1, xl: 1 }}
						mb='20px'>
						<BarWrapper
							data={monthlyAgentAgentInvites}
							title='Agent-Agent Invites Per Month'
						/>
					</SimpleGrid>
					<SimpleGrid
						minH={'45vh'}
						columns={{ base: 1, md: 1, xl: 1 }}
						mb='20px'>
						<BarWrapper
							data={monthlyAgentClientInvites}
							title='Agent-Client Invites Per Month'
						/>
					</SimpleGrid>
					<SimpleGrid
						minH={'45vh'}
						columns={{ base: 1, md: 1, xl: 1 }}
						mb='20px'>
						<BarWrapper
							data={monthlyClientClientInvites}
							title='Client-Client Invites Per Month'
						/>
					</SimpleGrid>
					<Text
						color={textColor}
						fontSize='2xl'
						fontWeight='bold'
						my={4}>
						Overall Stats
					</Text>
					<SimpleGrid
						columns={{ base: 1, md: 1, xl: 2 }}
						gap='20px'
						mb='20px'
						minH={'45vh'}>
						<PieCard chartdata={pieChartData} />
					</SimpleGrid>
				</>
			)}
		</Box>
	);
};

export default UserReports;
