import { Box, Spinner } from "@chakra-ui/react";
import { UtopiaLogo } from "components/icons/Icons";
import React from "react";

const Splash = () => {
  // let logoColor = useColorModeValue("white", "white");

  return (
    <Box
      display="flex"
      alignItems={"center"}
      justifyContent="center"
      height={"100vh"}
      flexDirection="column"
    >
      <UtopiaLogo h="60px" w="60px" my="32px" color={"white"} />
      <Spinner />
    </Box>
  );
};

export default Splash;
