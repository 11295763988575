import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import axios from "axios";
import { createCoBuyerApplication } from "services/applications.service";

const UpdateApplicationConfirmation = ({
  title,
  message,
  onClose,
  isOpen,
  loading,
  onConfirm,
  changelog,
  selectedUser,
}) => {
  const cancelRef = React.useRef();
  const toast = useToast();

  const handleAddingCoBuyer = async () => {
    try {
      let prevStepOne = null;
      let newStepOne = null;
      let newStepTwo = null;
      let newStepThree = null;
      let newStepFour = null;
      let newStepFive = null;
      for (let i = 0; i < changelog.length; i++) {
        const newLog = changelog[i].new;
        const prevLog = changelog[i].previous;

        if (newLog.question === "Do you have a Co-Buyer?") {
          prevStepOne = prevLog.answer;
          newStepOne = newLog.answer;
        } else if (
          newLog.question ===
          "Please enter the details of your Co-Buyer - First Name:"
        ) {
          newStepTwo = newLog.answer;
        } else if (
          newLog.question ===
          "Please enter the details of your Co-Buyer - Last Name:"
        ) {
          newStepThree = newLog.answer;
        } else if (
          newLog.question ===
          "Please enter the details of your Co-Buyer - Email:"
        ) {
          newStepFour = newLog.answer;
        } else if (
          newLog.question ===
          "Please enter the details of your Co-Buyer - Phone Number:"
        ) {
          newStepFive = newLog.answer;
        }
      }

      prevStepOne = prevStepOne?.toLowerCase();
      newStepOne = newStepOne?.toLowerCase();

      if (
        prevStepOne === "no" &&
        newStepOne === "yes" &&
        newStepTwo &&
        newStepThree &&
        newStepFour &&
        newStepFive
      ) {
        const addingCoBuyerSuccess = await createCoBuyerApplication(
          newStepTwo,
          newStepThree,
          newStepFour,
          selectedUser.id,
          selectedUser.firstName,
          selectedUser.lastName,
          selectedUser.email
        );

        if (addingCoBuyerSuccess) {
          toast({
            position: "top-right",
            title: "Co-Buyer created.",
            description: "Co-Buyer created.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>
                Are you sure you want to make the following changes to this
                application:
              </Text>

              {changelog?.length > 0 ? (
                changelog.map((change, index) => {
                  return (
                    <Box mt={5}>
                      <Text fontWeight={"semibold"} mb={2}>
                        {change?.new?.question}
                      </Text>

                      <Flex
                        alignItems={"center"}
                        justifyContent="space-between"
                      >
                        <Flex
                          borderWidth={1}
                          flex={1}
                          alignItems="center"
                          justifyContent={"center"}
                          borderRadius={4}
                          borderColor="red.400"
                          minH={7}
                          p={1}
                        >
                          <Text>{change?.previous?.answer ?? "-"}</Text>
                        </Flex>

                        <ArrowForwardIcon mx={3} />
                        <Flex
                          borderWidth={1}
                          flex={1}
                          alignItems="center"
                          justifyContent={"center"}
                          borderRadius={4}
                          borderColor="green.400"
                          p={1}
                        >
                          <Text>{change?.new?.answer ?? "-"}</Text>
                        </Flex>
                      </Flex>
                    </Box>
                  );
                })
              ) : (
                <></>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={async () => {
                  if (!loading) {
                    await handleAddingCoBuyer();
                    onConfirm();
                  }
                }}
                ml={3}
              >
                {loading ? <Spinner /> : "Update"}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default UpdateApplicationConfirmation;
