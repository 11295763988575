import React, { Component } from "react";
import Chart from "react-apexcharts";

class BarChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: "basic-bar",
        },
        xaxis: {
          categories: [],
        },
      },
      series: [
        {
          name: "Applications",
          data: [],
        },
      ],
    };
  }

  componentDidMount() {
    let array = this.props?.chartData;
    let max = 0;

    if (array) {
      for (let i = 0; i < array?.length; i++) {
        if (array[i] > max) {
          max = array[i];
        }
      }

      this.setState({
        options: {
          chart: {
            id: "basic-bar",
          },
          xaxis: {
            categories: this.props.categories,
          },
          yaxis: [
            {
              min: 0,
              max: max > 10 ? max * 1.5 : 10,
              labels: {
                formatter: function (val) {
                  return parseInt(val);
                },
              },
            },
          ],
        },
        series: [
          {
            name: "Applications",
            data: this.props.chartData,
          },
        ],
      });
    }
  }

  render() {
    return (
      <>
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          width="100%"
          height="100%"
        />
      </>
    );
  }
}

export default BarChart;
