import React, { Fragment, useEffect, useState } from 'react';
import { getAllApplicationsData } from '../../../services/applications.service';
import { useSelector } from 'react-redux';

// chakra
import {
	Box,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	TableCaption,
	TableContainer,
	Button,
	IconButton,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';

const Landlords = () => {
	const [landlordApplications, setLandlordApplications] = useState(
		[]
	);

	const { landlords } = useSelector((state) => state.user);

	useEffect(() => {
		const fetchData = async () => {
			setLandlordApplications(landlords);
		};

		fetchData().catch(console.error);
	}, [landlords]);

	console.log({
		landlords,
	});

	return (
		<Fragment>
			<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
				<Box
					bg='white'
					rounded='lg'
					p='4'>
					<TableContainer>
						<Table variant='simple'>
							<Thead>
								<Tr>
									<Th>Email</Th>
									<Th>Address</Th>
									<Th>Status</Th>
								</Tr>
							</Thead>

							<Tbody>
								{landlords.map((user) => (
									<Tr>
										<Td>{user?.email}</Td>
										<Td>{user?.landlordPropertyData?.address}</Td>
										<Td>{user?.landlordPropertyData?.status}</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</TableContainer>
				</Box>
			</Box>
		</Fragment>
	);
};

export default Landlords;
