import { DownloadIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import {
	Box,
	Flex,
	Heading,
	Image,
	Link,
	SimpleGrid,
	Spinner,
	Text,
	useToast,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import heic2any from 'heic2any';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import {
	getApplicationAdditionalDocuments,
	getCoapplicantData,
	getCoapplicantStorageFiles,
	getCoapplicantData_Optimized,
} from 'services/applications.service';
import { handleDownload } from 'utils/helpers';

const DocumentsTab = ({
	selectedUser,
	loading,
	applicationData,
	storageData,
}) => {
	const [cobuyerloading, setCobuyerLoading] = useState(false);
	const [heicLoading, setHeicLoading] = useState(false);

	const [coapplicationStorageFiles, setCoapplicantStorageFiles] =
		useState();
	const [additionalDocuments, setAdditionalDocuments] = useState({
		buyerAdditionalDocuments: [],
		coBuyerAdditionalDocuments: [],
	});

	const [modifiedStorage, setModifiedStorage] = useState(storageData);
	const toast = useToast();

	const loadCoapplicantData = async () => {
		try {
			setCobuyerLoading(true);
			const response = await getCoapplicantData_Optimized(
				selectedUser.id
			);

			const documentsRes = await getApplicationAdditionalDocuments(
				selectedUser.id,
				response?.id
			);

			const convertedDocs = await convertAdditionalFilesHeic(
				documentsRes
			);
			setAdditionalDocuments(convertedDocs);
			if (!response) return;
			const storageRes = await getCoapplicantStorageFiles(
				response.id
			);
			const convertedCoappStorage =
				await handleStorageFilesConversion(storageRes);
			setCoapplicantStorageFiles(convertedCoappStorage);
		} catch (err) {
			toast({
				title: 'Something went wrong',
				description:
					'Something wen wrong while getting coapplicant details. Please try again.',
				status: 'error',
				duration: 4000,
				isClosable: true,
				position: 'bottom',
			});
		} finally {
			setCobuyerLoading(false);
		}
	};

	const convertHeicToJpg = async (heicUrl) => {
		try {
			const jpgBlob = await heic2any({
				blob: await fetch(heicUrl).then((res) => res.blob()),
			});
			const jpgUrl = URL.createObjectURL(jpgBlob);

			return jpgUrl;
		} catch (error) {
			console.error('Error converting HEIC to JPG:', error);
			return null;
		}
	};

	const handleStorageFilesConversion = async (
		documents,
		isModified
	) => {
		try {
			setHeicLoading(true);
			let tempStorageData = { ...documents };

			const [bvRes, bsRes, ivRes] = await Promise.all([
				processDocuments(documents?.backgroundVerification),
				processDocuments(documents?.bankStatements),
				processDocuments(documents?.incomeVerification),
			]);

			tempStorageData = {
				...tempStorageData,
				incomeVerification: ivRes,
				bankStatements: bsRes,
				backgroundVerification: bvRes,
			};

			if (isModified) {
				setModifiedStorage(tempStorageData);
			}

			return tempStorageData;
		} finally {
			setHeicLoading(false);
		}
	};

	const processDocuments = async (documents) => {
		let temp = [...documents];

		await Promise.all(
			temp.map(async (doc, index) => {
				if (doc.contentType === 'image/heic') {
					let normalUrl = await convertHeicToJpg(doc.url);
					temp[index] = { ...temp[index], url: normalUrl };
				}
			})
		);

		return temp;
	};

	const convertAdditionalFilesHeic = async (documents) => {
		let tempStorageData = { ...documents };

		const [buyerDocs, coBuyerDocs] = await Promise.all([
			processDocuments(documents?.buyerAdditionalDocuments),
			processDocuments(documents?.coBuyerAdditionalDocuments),
		]);

		tempStorageData = {
			buyerAdditionalDocuments: buyerDocs,
			coBuyerAdditionalDocuments: coBuyerDocs,
		};

		return tempStorageData;
	};

	useEffect(() => {
		if (storageData) {
			handleStorageFilesConversion(storageData, true);
		}
	}, [storageData]);

	useEffect(() => {
		setModifiedStorage([]);
		setAdditionalDocuments({
			buyerAdditionalDocuments: [],
			coBuyerAdditionalDocuments: [],
		});
		setCoapplicantStorageFiles(null);
		// if (applicationData)

		let timerId;

		clearTimeout(timerId);

		// Set a new timeout to run the effect after 1 second (1000 milliseconds)
		timerId = setTimeout(() => {
			if (applicationData) {
				// getStorageData(selectedUser.id);
				loadCoapplicantData();
				// loadCoapplicantData();
			}
		}, 1000);

		// Clean up the timeout on unmount or when selectedUser changes
		return () => clearTimeout(timerId);
	}, [applicationData]);

	function renderDocuments(docType, title, type) {
		let storageDisplayData = modifiedStorage;

		if (type === 'coapp') {
			storageDisplayData = coapplicationStorageFiles;
		} else if (type === 'additional') {
			storageDisplayData = additionalDocuments;
		}

		const docs = storageDisplayData?.[docType];
		const isDocsFound = docs?.length > 0;

		return (
			<Box>
				<Heading
					fontSize='22px'
					my={3}>
					{title}
				</Heading>
				{isDocsFound ? (
					<SimpleGrid
						columns={coapplicationStorageFiles ? 1 : 2}
						spacing={0}
						backgroundColor='rgba(0,0,0,0.1)'>
						{docs.map((item, index) => {
							const isPdf = item.contentType === 'application/pdf';
							const HAS_METADATA = Boolean(
								item.customMetadata?.fileTypeName
							);

							return (
								<Box
									padding='3'
									borderWidth={1}
									borderColor='white'
									key={index}>
									{isPdf ? (
										<>
											{HAS_METADATA && (
												<Text fontWeight='semibold'>
													{index + 1}.{' '}
													{item.customMetadata?.fileTypeName}
												</Text>
											)}
											<Text fontWeight='semibold'>
												{HAS_METADATA
													? 'File Name: '
													: `${index + 1}.`}{' '}
												{item.name}
											</Text>
											<Text mb={2}>
												Uploaded On:{' '}
												{moment(item.timeCreated)
													.tz('America/New_York')
													.format('MM-DD-YYYY')}
											</Text>
											<Link
												href={item.url}
												isExternal
												color='blue.500'
												fontWeight='medium'
												fontSize='16px'>
												{item.name} <ExternalLinkIcon mx='2px' />
											</Link>
										</>
									) : (
										<>
											{HAS_METADATA && (
												<Text fontWeight='semibold'>
													{index + 1}.{' '}
													{item.customMetadata?.fileTypeName}
												</Text>
											)}
											<Text fontWeight='semibold'>
												{HAS_METADATA
													? 'File Name: '
													: `${index + 1}.`}{' '}
												{item.name}
											</Text>
											<Text mb={2}>
												Uploaded On:{' '}
												{moment(item.timeCreated)
													.tz('America/New_York')
													.format('MM-DD-YYYY')}
											</Text>
											{/* <Box
                        alignItems="flex-start"
                        justifyContent="center"
                        display="flex"
                      >
                        <Box position="relative">
                          <Image
                            boxSize="300px"
                            objectFit="cover"
                            src={item.url}
                            alt={item.name}
                          />
                          <DownloadIcon
                            onClick={() => handleDownload(item.url, item.name)}
                            boxSize="2em"
                            color="white"
                            cursor="pointer"
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              backgroundColor: "rgba(0,0,0,0.2)",
                              padding: "5px",
                            }}
                          />
                        </Box>
                      </Box> */}
											<Box
												alignItems='flex-start'
												justifyContent='center'
												display='flex'>
												<Box position='relative'>
													{item.contentType === 'image/heic' ? (
														<>
															<img
																src={item.url}
																alt={item.name}
																// onLoad={() => convertHeicToJpg(item.url, index)}
															/>
														</>
													) : (
														<Image
															boxSize='300px'
															objectFit='cover'
															src={item.url}
															alt={item.name}
														/>
													)}
													<DownloadIcon
														onClick={() =>
															handleDownload(item.url, item.name)
														}
														boxSize='2em'
														color='white'
														cursor='pointer'
														style={{
															position: 'absolute',
															top: 0,
															right: 0,
															backgroundColor: 'rgba(0,0,0,0.2)',
															padding: '5px',
														}}
													/>
												</Box>
											</Box>
										</>
									)}
								</Box>
							);
						})}
					</SimpleGrid>
				) : (
					<Text>No Documents Found</Text>
				)}
			</Box>
		);
	}

	function renderNoFilesFoundText() {
		const isNoFilesFound =
			!applicationData &&
			storageData?.incomeVerification?.length === 0 &&
			storageData?.backgroundVerification?.length === 0 &&
			storageData?.bankStatements?.length === 0;
		return isNoFilesFound ? (
			<Text
				fontWeight='bold'
				color='red.300'
				style={{ fontSize: '22px' }}>
				No Files Found
			</Text>
		) : null;
	}

	return loading || heicLoading ? (
		<Card
			style={{
				height: '80vh',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
			<Spinner size='xl' />
		</Card>
	) : (
		<Flex justifyContent={'space-between'}>
			<Box
				flex={1}
				mt={5}>
				{renderNoFilesFoundText()}

				{renderDocuments(
					'incomeVerification',
					'Income Verification Documents'
				)}
				{renderDocuments(
					'backgroundVerification',
					'Background Verification Documents'
				)}
				{renderDocuments('bankStatements', 'Bank Statements')}

				{additionalDocuments?.buyerAdditionalDocuments && (
					<>
						{renderDocuments(
							'buyerAdditionalDocuments',
							'Additional Documents',
							'additional'
						)}
					</>
				)}
			</Box>

			{cobuyerloading ? (
				<>
					<Flex
						flex={1}
						maxW={'45vw'}
						alignItems={'center'}
						justifyContent={'center'}>
						<Spinner size='xl' />
					</Flex>
				</>
			) : (
				coapplicationStorageFiles && (
					<>
						<Box
							borderWidth={1}
							mx={5}
						/>
						<Box
							flex={1}
							maxW={'45vw'}
							mt={5}>
							{renderNoFilesFoundText()}
							{renderDocuments(
								'incomeVerification',
								'Income Verification Documents',
								'coapp'
							)}
							{renderDocuments(
								'backgroundVerification',
								'Background Verification Documents',
								'coapp'
							)}
							{renderDocuments(
								'bankStatements',
								'Bank Statements',
								'coapp'
							)}

							{additionalDocuments?.coBuyerAdditionalDocuments && (
								<>
									{renderDocuments(
										'coBuyerAdditionalDocuments',
										'Additional Documents',
										'additional'
									)}
								</>
							)}
						</Box>
					</>
				)
			)}
		</Flex>
	);
};

export default DocumentsTab;
