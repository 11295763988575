import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Flex,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import React, { memo } from "react";

import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

const CustomTable = ({ data, onRowPress, columns, onActionPress, loading }) => {
  const [sorting, setSorting] = React.useState([]);

  const {
    getHeaderGroups,
    setPageIndex,
    getCanPreviousPage,
    previousPage,
    getState,
    getPageCount,
    setPageSize,
    nextPage,
    getCanNextPage,
    getRowModel,
  } = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const currentPage = getState().pagination.pageIndex;
  const pageSize = getState().pagination.pageSize;

  return (
    <>
      <Box
        style={{
          width: "100%",
          // height: "73vh",
          overflow: "auto",
        }}
        px={2}
      >
        <Table
          className="-striped -highlight"
          style={{
            height: "100%",
          }}
        >
          <Thead borderBottomWidth={"1.5px"}>
            {getHeaderGroups()?.map((headerGroup) => (
              <Tr key={headerGroup.id} cursor="pointer">
                {headerGroup.headers?.map((header) => {
                  return (
                    <Th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <Box
                          display={"flex"}
                          alignItems="center"
                          justifyContent="center"
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer select-none"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          <Text
                            fontWeight="bold"
                            textAlign="center"
                            userSelect="none"
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </Text>
                          <Text ml="5px">
                            {{
                              asc: <ChevronUpIcon h={5} w={5} />,
                              desc: <ChevronDownIcon h={5} w={5} />,
                            }[header.column.getIsSorted()] ?? null}
                          </Text>
                        </Box>
                      )}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>

          <Tbody
            borderBottomWidth={"1.5px"}
            maxHeight="70vh"
            overflow={"scroll"}
          >
            {getRowModel()
              .rows.slice(
                currentPage * pageSize,
                currentPage * pageSize + pageSize
              )
              .map((row) => {
                return (
                  <Tr
                    key={row.id}
                    cursor="pointer"
                    style={{
                      height: "10px",
                    }}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          whiteSpace="nowrap"
                          textAlign={"center"}
                          onClick={() => {
                            if (cell?.column?.id === "actions") {
                              onActionPress(row.original);
                            } else {
                              onRowPress && onRowPress(row.original);
                            }
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>

      <Flex justifyContent="space-between" m={4} alignItems="center">
        <Flex>
          <Tooltip label="First Page">
            <IconButton
              onClick={() => setPageIndex(0)}
              isDisabled={!getCanPreviousPage()}
              icon={<ArrowLeftIcon h={3} w={3} />}
              mr={4}
            />
          </Tooltip>
          <Tooltip label="Previous Page">
            <IconButton
              onClick={() => previousPage()}
              isDisabled={!getCanPreviousPage()}
              icon={<ChevronLeftIcon h={6} w={6} />}
            />
          </Tooltip>
        </Flex>

        <Flex alignItems="center">
          <Text flexShrink="0" mr={8}>
            Page{" "}
            <Text fontWeight="bold" as="span">
              {getState().pagination.pageIndex + 1}
            </Text>{" "}
            of{" "}
            <Text fontWeight="bold" as="span">
              {getPageCount()}
            </Text>
          </Text>
          <Text flexShrink="0">Go to page:</Text>{" "}
          <NumberInput
            ml={2}
            mr={8}
            w={28}
            min={1}
            max={getPageCount().length}
            onChange={(value) => {
              const page = value ? value - 1 : 0;
              setPageIndex(page);
            }}
            defaultValue={getState().pagination.pageIndex + 1}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Select
            w={32}
            value={getState().pagination.pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </Flex>

        <Flex>
          <Tooltip label="Next Page">
            <IconButton
              onClick={() => nextPage()}
              isDisabled={!getCanNextPage()}
              icon={<ChevronRightIcon h={6} w={6} />}
            />
          </Tooltip>
          <Tooltip label="Last Page">
            <IconButton
              onClick={() => setPageIndex(getPageCount() - 1)}
              isDisabled={!getCanNextPage()}
              icon={<ArrowRightIcon h={3} w={3} />}
              ml={4}
            />
          </Tooltip>
        </Flex>
      </Flex>
    </>
  );
};

export default memo(CustomTable);
