import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useAuth } from "contexts/AuthContext";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getAllInitiatedOffers,
  getAllInitiatedOffers_Optimized,
} from "services/offers.service";
import OffersList from "./components/OffersList";
import ConfirmationDialog from "components/confirmationDialog";
import { changeOfferStatusToDeleted } from "services/offers.service";
import { getUserById } from "services/applications.service";
import { getApplicationByUserId } from "services/applications.service";
import { getUserFilesById } from "services/applications.service";
import { getHomebuyerFilesById } from "services/applications.service";
import { getUserApplication } from "services/applications.service";
import { getHomebuyerById } from "services/applications.service";

const InitiatedOffers = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [applicationData, setApplicationData] = useState();
  const [storageData, setStorageData] = useState();
  const [storageLoading, setStorageLoading] = useState(false);

  const [loading, setLoading] = useState(false);
  const [selectionLoading, setSelectionLoading] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [userName, setUserName] = useState("");

  const { searchResults } = useAuth();
  const { initiatedOffers } = useSelector((state) => state.offer);
  const [deleteId, setDeleteId] = useState(null);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: loadingOpen,
    onOpen: onLoadingOpen,
    onClose: onLoadingClose,
  } = useDisclosure();

  const getScreenData = async () => {
    setLoading(true);
    try {
      await getAllInitiatedOffers_Optimized();
    } catch (err) {
      toast({
        title: "Error",
        description:
          "Unable to get Offers data at the moment. Please try again",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmationOpen = (userId, name) => {
    setUserName(name);

    setDeleteId(userId);
    onOpen();
  };

  const handleDelete = async () => {
    try {
      setDialogLoading(true);
      await changeOfferStatusToDeleted(deleteId);
      await getScreenData();

      setDeleteId(null);
      setDialogLoading(false);
      onClose();
      toast({
        title: "Success",
        description: "User deleted successfully",
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    } catch (err) {
      console.log("🚀 ~ file: index.jsx:70 ~ handleDelete ~ err:", err);
      setDeleteId(null);
      setDialogLoading(false);
      onClose();

      toast({
        title: "Error",
        description:
          "Unable to delete application at the moment. Please try again later",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const getStorageData = async (userId) => {
    try {
      setStorageLoading(true);
      let storageRes = await getUserFilesById(userId);

      if (
        storageRes.backgroundVerification?.length === 0 &&
        storageRes.bankStatements?.length === 0 &&
        storageRes.incomeVerification.length === 0
      ) {
        storageRes = await getHomebuyerFilesById(userId);
      }

      setStorageData(storageRes);
    } catch (err) {
      toast({
        title: "Error",
        description:
          "Something went wrong while getting user files. Please try again",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setStorageLoading(false);
    }
  };

  const handleSelectRow = async (rowData) => {
    try {
      setSelectionLoading(true);
      onLoadingOpen();
      const [user, application] = await Promise.all([
        getHomebuyerById(rowData.clientId),
        getUserApplication(rowData.clientId),
      ]);
      getStorageData(rowData?.clientId);
      const appStepSeven = application?.stepSeven;

      const username = user.legalName
        ? user?.legalName?.firstName + " " + user?.legalName?.lastName
        : appStepSeven
        ? appStepSeven[0]?.answer +
          " " +
          appStepSeven[1].answer +
          " " +
          appStepSeven[2].answer
        : "";

      const firstName =
        user?.legalName?.firstName ?? appStepSeven
          ? appStepSeven[0]?.answer
          : "";

      const lastName =
        user?.legalName?.firstName ?? appStepSeven
          ? appStepSeven[2]?.answer
          : "";

      setApplicationData(application);
      setSelectedUser({
        ...user,
        application,
        name: username,
        firstName,
        lastName,
      });
    } catch (err) {
      toast({
        title: "Error",
        description:
          "Unable to open the application at the moment. Please try again.",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setSelectionLoading(false);
      onLoadingClose();
    }
  };

  useEffect(() => {
    getScreenData();
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ConfirmationDialog
        title="Delete Application"
        message={`Are you sure you want to delete <b>${userName}</b> offer?`}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        loading={dialogLoading}
        onConfirm={() => handleDelete()}
      />

      <Modal onClose={onLoadingClose} isOpen={loadingOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Flex
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              <Text textAlign={"center"} fontWeight={"bold"}>
                Loading Application...
              </Text>
              <Spinner size="lg" mt={2} />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      <OffersList
        data={searchResults ? searchResults : initiatedOffers}
        setSelectedUser={setSelectedUser}
        selectedUser={selectedUser}
        storageData={storageData}
        setStorageData={setStorageData}
        loading={storageLoading}
        applicationData={applicationData}
        setApplicationData={setApplicationData}
        handleDelete={handleConfirmationOpen}
        getStorageData={getStorageData}
        handleSelectRow={handleSelectRow}
        selectionLoading={selectionLoading}
      />
    </Box>
  );
};

export default InitiatedOffers;
