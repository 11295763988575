import { createContext, useContext } from 'react';

const TimezoneContext = createContext();

const TimezoneProvider = ({ children, timezone }) => {
	return (
		<TimezoneContext.Provider value={timezone}>
			{children}
		</TimezoneContext.Provider>
	);
};

const useTimezone = () => {
	const context = useContext(TimezoneContext);
	if (!context) {
		throw new Error(
			'useTimezone must be used within a TimezoneProvider'
		);
	}
	return context;
};

export { TimezoneProvider, useTimezone };
