import { Box, Portal, useDisclosure } from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin.js';

import Navbar from 'components/navbar/NavbarAdmin.js';
import Sidebar from 'components/sidebar/Sidebar.js';
import { SidebarContext } from 'contexts/SidebarContext';
import { useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from 'routes.js';
import {
	getAgentInvites,
	updateNameInInvites,
	getAllAgentsData,
	getAgentInvitesByDateRange,
	getAllAgentsDataByDateRange,
	getMonthlyAgentAndInviteStats,
} from 'services/agents.service';
import {
	getAllApplicationsData,
	getGraphsDataBasedOnDateRange,
	getMonthlyStats,
	getMonthlyStats_New,
} from 'services/applications.service';
import {
	getAllInitiatedOffers,
	getAllInitiatedOffers_Optimized,
} from 'services/offers.service';
import {
	handleGetUsers,
	handleGetAgents,
	handleGetOffers,
	handleGetApplicationResponses,
	handleGetHomebuyerApplicationResponses,
	handleGetCoapplicantApplicationResponses,
	handleGetAgentApplicationResponses,
	handleGetAgentInvites,
} from 'services/data.service';

const Dashboard = (props) => {
	const { ...rest } = props;
	const [fixed] = useState(false);
	const [toggleSidebar, setToggleSidebar] = useState(false);

	const displayRoutes = useMemo(() => {
		return routes.filter((route) => route.layout === '/admin');
	}, []);

	const getRoute = () => {
		return window.location.pathname !== '/admin/full-screen-maps';
	};

	const getActiveRoute = (routes) => {
		let activeRoute = 'Default Brand Text';
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = getActiveRoute(routes[i].items);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else if (routes[i].category) {
				let categoryActiveRoute = getActiveRoute(routes[i].items);
				if (categoryActiveRoute !== activeRoute) {
					return categoryActiveRoute;
				}
			} else {
				if (
					window.location.href?.indexOf(
						routes[i].layout + routes[i].path
					) !== -1
				) {
					return routes[i].name;
				}
			}
		}
		return activeRoute;
	};

	const getActiveNavbar = (routes) => {
		let activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveNavbar = getActiveNavbar(routes[i].items);
				if (collapseActiveNavbar !== activeNavbar) {
					return collapseActiveNavbar;
				}
			} else if (routes[i].category) {
				let categoryActiveNavbar = getActiveNavbar(routes[i].items);
				if (categoryActiveNavbar !== activeNavbar) {
					return categoryActiveNavbar;
				}
			} else {
				if (
					window.location.href?.indexOf(
						routes[i].layout + routes[i].path
					) !== -1
				) {
					return routes[i].secondary;
				}
			}
		}
		return activeNavbar;
	};

	const getActiveNavbarText = (routes) => {
		let activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveNavbar = getActiveNavbarText(
					routes[i].items
				);
				if (collapseActiveNavbar !== activeNavbar) {
					return collapseActiveNavbar;
				}
			} else if (routes[i].category) {
				let categoryActiveNavbar = getActiveNavbarText(
					routes[i].items
				);
				if (categoryActiveNavbar !== activeNavbar) {
					return categoryActiveNavbar;
				}
			} else {
				if (
					window.location.href?.indexOf(
						routes[i].layout + routes[i].path
					) !== -1
				) {
					return routes[i].messageNavbar;
				}
			}
		}
		return activeNavbar;
	};

	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.layout === '/admin') {
				return (
					<Route
						path={prop.layout + prop.path}
						component={prop.component}
						key={key}
					/>
				);
			}
			if (prop.collapse) {
				return getRoutes(prop.items);
			}
			if (prop.category) {
				return getRoutes(prop.items);
			} else {
				return null;
			}
		});
	};

	document.documentElement.dir = 'ltr';
	const { onOpen } = useDisclosure();

	const initialDataUpdate = async () => {
		//new
		await handleGetUsers();
		await handleGetAgents();
		await handleGetOffers();
		await handleGetApplicationResponses();
		await handleGetHomebuyerApplicationResponses();
		await handleGetCoapplicantApplicationResponses();
		await handleGetAgentApplicationResponses();
		await handleGetAgentInvites();

		getAgentInvites();
		getAgentInvitesByDateRange();
		getGraphsDataBasedOnDateRange();
		getAllApplicationsData();
		getAllAgentsData();
		getAllAgentsDataByDateRange();
		// getMonthlyStats();
		getMonthlyStats_New();
		getMonthlyAgentAndInviteStats();
		updateNameInInvites();
		getAllInitiatedOffers_Optimized();
	};

	useEffect(() => {
		const fetchData = async () => {
			await initialDataUpdate();
		};

		fetchData().catch(console.error);
	}, []);

	// useEffect(() => {
	// 	const fetchData = async () => {
	// 		await initialDataUpdate();

	// 		let intervalId;
	// 		let prevTime = Date.now();

	// 		const updateInterval = () => {
	// 			const currTime = Date.now();
	// 			const elapsedTime = currTime - prevTime;
	// 			const interval =
	// 				elapsedTime < 120000 ? 120000 - elapsedTime : 0;
	// 			intervalId = setTimeout(() => {
	// 				initialDataUpdate();
	// 				prevTime = Date.now();
	// 				updateInterval();
	// 			}, interval);
	// 		};

	// 		updateInterval();

	// 		return () => clearTimeout(intervalId);
	// 	};

	// 	fetchData().catch(console.error);
	// }, []);

	return (
		<Box>
			<SidebarContext.Provider
				value={{
					toggleSidebar,
					setToggleSidebar,
				}}>
				<Sidebar
					routes={displayRoutes}
					display='none'
					{...rest}
				/>
				<Box
					float='right'
					minHeight='100vh'
					height='100%'
					overflow='auto'
					position='relative'
					maxHeight='100%'
					w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
					maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
					transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
					transitionDuration='.2s, .2s, .35s'
					transitionProperty='top, bottom, width'
					transitionTimingFunction='linear, linear, ease'>
					<Portal>
						<Box>
							<Navbar
								onOpen={onOpen}
								brandText={getActiveRoute(displayRoutes)}
								secondary={getActiveNavbar(displayRoutes)}
								message={getActiveNavbarText(displayRoutes)}
								fixed={fixed}
								{...rest}
							/>
						</Box>
					</Portal>

					{getRoute() ? (
						<Box
							mx='auto'
							p={{ base: '20px', md: '30px' }}
							pe='20px'
							minH='100vh'
							pt='50px'>
							<Switch>
								{getRoutes(displayRoutes)}
								<Redirect to='/admin/dashboard' />
							</Switch>
						</Box>
					) : null}
					<Box>
						<Footer />
					</Box>
				</Box>
			</SidebarContext.Provider>
		</Box>
	);
};

export default Dashboard;
