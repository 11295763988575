import { Icon } from '@chakra-ui/react';
import {
	MdAddBusiness,
	MdAnalytics,
	MdArrowCircleDown,
	MdAvTimer,
	MdInsertDriveFile,
	MdLock,
	MdLockClock,
	MdOfflinePin,
	MdOutlineFolderOpen,
	MdOutlineGridView,
	MdOutlineRealEstateAgent,
	MdPauseCircleFilled,
	MdPendingActions,
	MdPersonOff,
	MdUnpublished,
} from 'react-icons/md';
import Agents from 'views/admin/agents';
import UserReports from 'views/admin/dashboardMain';
import Users from 'views/admin/users';
import Others from 'views/admin/others';
// import Users from "views/admin/dataTables/users";
import SignInCentered from 'views/auth/signIn';

import {
	BsFileEarmarkCheck,
	BsFillPersonCheckFill,
	BsPersonCheck,
} from 'react-icons/bs';
import { FaUserClock } from 'react-icons/fa';
import { FcInspection } from 'react-icons/fc';
import { GiCheckMark } from 'react-icons/gi';
import { CgFileAdd } from 'react-icons/cg';
import { TbChecks } from 'react-icons/tb';
import Invites from 'views/admin/invites';
import AgentInvites from 'views/admin/agentInvites';
import InitiatedOffers from 'views/admin/initiatedOffers';
import HomebuyerInvites from 'views/admin/homebuyerInvites';
import WaitlistUsers from 'views/admin/waitlistUsers';
import Landlords from 'views/admin/landlords';

const routes = [
	{
		name: 'Dashboard',
		layout: '/admin',
		icon: (
			<Icon
				as={MdAnalytics}
				width='20px'
				height='20px'
				color='inherit'
			/>
		),
		path: '/dashboard',
		component: UserReports,
		subRoutes: [],
	},

	{
		name: `All Applications`,
		layout: '/admin',
		icon: (
			<Icon
				as={MdOutlineGridView}
				width='20px'
				height='20px'
				color='inherit'
			/>
		),
		path: '/application/all',
		component: Users,
		subRoutes: [],
	},
	{
		name: 'Started Application',
		layout: '/admin',
		icon: (
			<Icon
				as={MdAvTimer}
				width='20px'
				height='20px'
				color='inherit'
			/>
		),
		path: '/application/started',
		component: Users,
		subRoutes: [],
	},
	{
		name: 'Othered Applications',
		layout: '/admin',
		icon: (
			<Icon
				as={MdAvTimer}
				width='20px'
				height='20px'
				color='inherit'
			/>
		),
		path: '/application/othered',
		component: Others,
		subRoutes: [],
	},

	{
		name: 'Submitted Applications',
		layout: '/admin',
		icon: (
			<Icon
				as={MdInsertDriveFile}
				width='20px'
				height='20px'
				color='inherit'
			/>
		),
		path: '/applications',
		component: Users,
		subRoutes: [
			{
				name: 'All',
				layout: '/admin',
				icon: (
					<Icon
						as={MdOutlineGridView}
						width='20px'
						height='20px'
						color='inherit'
					/>
				),
				path: '/applications/all-submitted',
				component: Users,
			},

			{
				name: 'Inbox',
				layout: '/admin',
				icon: (
					<Icon
						as={MdOutlineFolderOpen}
						width='20px'
						height='20px'
						color='inherit'
					/>
				),
				path: '/applications/inbox',
				component: Users,
			},

			{
				name: 'Approved',
				layout: '/admin',
				icon: (
					<Icon
						as={TbChecks}
						width='20px'
						height='20px'
						color='inherit'
					/>
				),
				path: '/applications/approved',
				component: Users,
			},
			{
				name: 'Conditionally Approved',
				layout: '/admin',
				icon: (
					<Icon
						as={MdOfflinePin}
						width='20px'
						height='20px'
						color='inherit'
					/>
				),
				path: '/applications/conditionally-approved',
				component: Users,
			},
			{
				name: 'Request For Info',
				layout: '/admin',
				icon: (
					<Icon
						as={MdPendingActions}
						width='20px'
						height='20px'
						color='inherit'
					/>
				),
				path: '/applications/info-required',
				component: Users,
			},

			{
				name: 'In Review',
				layout: '/admin',
				icon: (
					<Icon
						as={MdLockClock}
						width='20px'
						height='20px'
						color='inherit'
					/>
				),
				path: '/applications/in-review',
				component: Users,
			},
			{
				name: 'Paused',
				layout: '/admin',
				icon: (
					<Icon
						as={MdPauseCircleFilled}
						width='20px'
						height='20px'
						color='inherit'
					/>
				),
				path: '/applications/paused',
				component: Users,
			},

			{
				name: 'Rejected',
				layout: '/admin',
				icon: (
					<Icon
						as={MdUnpublished}
						width='20px'
						height='20px'
						color='inherit'
					/>
				),
				path: '/applications/rejected',
				component: Users,
			},

			{
				name: 'Inactive',
				layout: '/admin',
				icon: (
					<Icon
						as={MdPersonOff}
						width='20px'
						height='20px'
						color='inherit'
					/>
				),
				path: '/applications/inactive',
				component: Users,
			},
		],
	},

	{
		name: 'Offers',
		layout: '/admin',
		icon: (
			<Icon
				as={MdAddBusiness}
				width='20px'
				height='20px'
				color='inherit'
			/>
		),
		path: '/offers',
		component: Agents,
		subRoutes: [
			{
				name: 'Agent Initiated',
				layout: '/admin',
				icon: (
					<Icon
						as={CgFileAdd}
						width='20px'
						height='20px'
						color='inherit'
					/>
				),
				path: '/offer-initiated',
				component: InitiatedOffers,
			},

			{
				name: 'Utopia Extended',
				layout: '/admin',
				icon: (
					<Icon
						as={FaUserClock}
						width='20px'
						height='20px'
						color='inherit'
					/>
				),
				path: '/applications/offer-extended',
				component: Users,
			},
			{
				name: 'Seller Accepted',
				layout: '/admin',
				icon: (
					<Icon
						as={BsFileEarmarkCheck}
						width='20px'
						height='20px'
						color='inherit'
					/>
				),
				path: '/applications/offer-accepted',
				component: Users,
			},
		],
	},

	{
		name: 'Closings',
		layout: '/admin',
		icon: (
			<Icon
				as={BsFillPersonCheckFill}
				width='20px'
				height='20px'
				color='inherit'
			/>
		),
		path: '/closings',
		component: Agents,

		subRoutes: [
			{
				name: 'Inspection Complete',
				layout: '/admin',
				icon: (
					<Icon
						as={FcInspection}
						width='20px'
						height='20px'
						color='inherit'
					/>
				),
				path: '/applications/inspection-complete',
				component: Users,
			},
			{
				name: 'Preclosing Complete',
				layout: '/admin',
				icon: (
					<Icon
						as={BsPersonCheck}
						width='20px'
						height='20px'
						color='inherit'
					/>
				),
				path: '/applications/preclosing-complete',
				component: Users,
			},
			{
				name: 'Offer Closed',
				layout: '/admin',
				icon: (
					<Icon
						as={GiCheckMark}
						width='20px'
						height='20px'
						color='inherit'
					/>
				),
				path: '/applications/offer-closed',
				component: Users,
			},
		],
	},

	{
		name: 'Initiated Offers Outside',
		layout: '/admin',
		icon: (
			<Icon
				as={CgFileAdd}
				width='20px'
				height='20px'
				color='inherit'
			/>
		),
		path: '/offer-initiated',
		component: InitiatedOffers,
		subRoutes: [],
	},

	{
		name: 'Agents',
		layout: '/admin',
		icon: (
			<Icon
				as={MdOutlineRealEstateAgent}
				width='20px'
				height='20px'
				color='inherit'
			/>
		),
		path: '/agents',
		component: Agents,
		subRoutes: [],
	},

	{
		name: 'Client - Client Invites',
		layout: '/admin',
		icon: (
			<Icon
				as={MdArrowCircleDown}
				width='20px'
				height='20px'
				color='inherit'
			/>
		),
		path: '/homebuyer_invites',
		component: HomebuyerInvites,
		subRoutes: [],
	},
	{
		name: 'Agent - Client Invites',
		layout: '/admin',
		icon: (
			<Icon
				as={MdArrowCircleDown}
				width='20px'
				height='20px'
				color='inherit'
			/>
		),
		path: '/client_invites',
		component: Invites,
		subRoutes: [],
	},

	{
		name: 'Agent - Agent Invites',
		layout: '/admin',
		icon: (
			<Icon
				as={MdArrowCircleDown}
				width='20px'
				height='20px'
				color='inherit'
			/>
		),
		path: '/agent_invites',
		component: AgentInvites,
		subRoutes: [],
	},

	{
		name: 'Waitlist Users',
		layout: '/admin',
		icon: (
			<Icon
				as={MdArrowCircleDown}
				width='20px'
				height='20px'
				color='inherit'
			/>
		),
		path: '/waitlist_users',
		component: WaitlistUsers,
		subRoutes: [],
	},

	{
		name: 'Landlords',
		layout: '/admin',
		icon: (
			<Icon
				as={MdArrowCircleDown}
				width='20px'
				height='20px'
				color='inherit'
			/>
		),
		path: '/landlords',
		component: Landlords,
		subRoutes: [],
	},

	{
		name: 'Sign In',
		layout: '/auth',
		path: '/sign-in',
		icon: (
			<Icon
				as={MdLock}
				width='20px'
				height='20px'
				color='inherit'
			/>
		),
		component: SignInCentered,
		subRoutes: [],
	},
];

export default routes;
