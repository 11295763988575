import { extendTheme } from "@chakra-ui/react";
import { CalendarDefaultTheme } from "@uselessdev/datepicker";

export const PickerTheme = extendTheme(CalendarDefaultTheme, {
  components: {
    Calendar: {
      parts: ["calendar"],

      baseStyle: {
        calendar: {
          rounded: "none",
          shadow: "none",
        },
      },
    },

    CalendarControl: {
      parts: ["button"],

      baseStyle: {
        button: {
          h: 6,
          px: 2,
          rounded: "none",
          fontSize: "sm",
          color: "white",
          bgColor: "blue.500",

          _hover: {
            bgColor: "blue.200",
          },

          _focus: {
            outline: "none",
            bgColor: "blue.200",
          },
        },
      },
    },
    CalendarDay: {
      baseStyle: {
        _hover: {
          bgColor: "blue.200",
        },
        _focus: {
          outline: "none",
          bgColor: "blue.200",
        },
        _selected: {
          bgColor: "blue.500",
        },
      },
    },
  },
});
