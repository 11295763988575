import { ChevronDownIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	Input,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Text,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import moment from 'moment-timezone';
import { useRef, useState, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { getGraphsDataBasedOnDateRange } from 'services/applications.service';
import { setFromDate, setToDate } from 'store/reducers/graphSlice';
import { changeDateFormat } from 'utils/helpers';

const DateFilter = ({ handleLoading }) => {
	const [selected, setSelected] = useState('today');
	const { fromDate, toDate } = useSelector((state) => state.graph);

	const menuRef = useRef();
	const dispatch = useDispatch();

	const handleDate = (value) => {
		let fromValue, toValue;

		if (value === 'today') {
			fromValue = new Date();
			toValue = new Date();

			console.log({ fromValue, toValue });
		} else if (value === 'yesterday') {
			let d = new Date();
			d.setDate(d.getDate() - 1);

			fromValue = d;
			toValue = d;
		} else if (value === 'seven_days') {
			let d = new Date();
			d.setDate(d.getDate() - 7);

			fromValue = d;
			toValue = new Date();
		} else if (value === 'this_month') {
			let date = new Date();
			let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
			let lastDay = new Date(
				date.getFullYear(),
				date.getMonth() + 1,
				0
			);

			fromValue = firstDay;
			toValue = lastDay;
		} else if (value === 'last_month') {
			let date = new Date();
			let firstDay = new Date(
				date.getFullYear(),
				date.getMonth() - 1,
				1
			);
			let lastDay = new Date(date.getFullYear(), date.getMonth(), 0);

			fromValue = firstDay;
			toValue = lastDay;
		}

		setSelected(value);
		batch(() => {
			dispatch(setFromDate(fromValue));
			dispatch(setToDate(toValue));
		});

		handleApply();
	};

	const handleApply = () => {
		handleLoading(true);
		getGraphsDataBasedOnDateRange(() => {
			handleLoading(false);
		});
	};

	return (
		<Card
			my={10}
			alignSelf='flex-end'>
			<Box
				display={'flex'}
				alignItems='center'
				justifyContent='space-between'>
				<Text
					fontSize='xl'
					fontWeight='bold'>
					Filter Data
				</Text>
				<Box
					display='flex'
					alignItems={'center'}>
					<Text
						mr={10}
						fontSize='md'
						fontWeight='bold'>
						Select Date Range
					</Text>

					<Menu
						autoSelect
						isLazy
						ref={menuRef}>
						{({ onClose }) => (
							<>
								<MenuButton
									rightIcon={<ChevronDownIcon />}
									borderWidth={1}
									px={12}
									py={1}
									borderRadius={5}>
									{moment(fromDate)
										.tz('America/New_York')
										.format('ll')}{' '}
									-{' '}
									{moment(toDate).tz('America/New_York').format('ll')}
								</MenuButton>
								<MenuList zIndex={9999}>
									<MenuItem
										_selected={selected === 'today'}
										closeOnSelect={false}
										onClick={() => handleDate('today')}>
										Today
									</MenuItem>
									<MenuItem
										closeOnSelect={false}
										onClick={() => handleDate('yesterday')}>
										Yesterday
									</MenuItem>
									<MenuItem
										closeOnSelect={false}
										onClick={() => handleDate('seven_days')}>
										Last 7 Days
									</MenuItem>
									<MenuItem
										closeOnSelect={false}
										onClick={() => handleDate('this_month')}>
										This Month
									</MenuItem>
									<MenuItem
										closeOnSelect={false}
										onClick={() => handleDate('last_month')}>
										Last Month
									</MenuItem>

									<Menu>
										<MenuButton
											rightIcon={<ChevronDownIcon />}
											px={3}>
											Custom Range
										</MenuButton>
										<MenuList>
											<MenuItem closeOnSelect={false}>
												<Box
													display={'flex'}
													flexDirection='column'>
													<Text mb={5}>Select Range</Text>
													<Box
														display={'flex'}
														alignItems='center'
														justifyContent={'space-between'}>
														<Input
															placeholder='Select Date and Time'
															size='md'
															type='date'
															// max={moment(toDate).format("YYYY-MM-DD")}
															onChange={(e) => {
																let newDate = changeDateFormat(
																	e.target.value
																);
																dispatch(
																	setFromDate(new Date(newDate))
																);
															}}
														/>
														<Text mx={5}>-</Text>
														<Input
															placeholder='Select Date and Time'
															size='md'
															type='date'
															onChange={(e) => {
																let newDate = changeDateFormat(
																	e.target.value
																);
																dispatch(
																	setToDate(new Date(newDate))
																);
															}}
														/>
													</Box>
												</Box>
											</MenuItem>
										</MenuList>
									</Menu>

									<MenuDivider />
									<Box
										display='flex'
										alignItems='center'
										justifyContent='space-between'
										width={'100%'}
										px={3}>
										<Button onClick={onClose}>Cancel</Button>
										<Button
											colorScheme='blue'
											onClick={() => {
												handleApply();
												onClose();
											}}>
											Apply
										</Button>
									</Box>
								</MenuList>
							</>
						)}
					</Menu>
				</Box>
			</Box>
		</Card>
	);
};

export default DateFilter;
