import { useToast } from '@chakra-ui/react';
import Splash from 'components/splash';
import { useAuth } from 'contexts/AuthContext';
import { onAuthStateChanged } from 'firebase/auth';
import AdminLayout from 'layouts/admin';
import AuthLayout from 'layouts/auth';
import { useEffect, useState } from 'react';
import {
	HashRouter,
	Redirect,
	Route,
	Switch,
} from 'react-router-dom';
import { getUserById } from 'services/applications.service';
import { auth } from 'utils/firebase';

import './App.css';

function App() {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [loading, setLoading] = useState(false);

	const toast = useToast();
	const { handleSignOut } = useAuth();

	useEffect(() => {
		setLoading(true);
		onAuthStateChanged(auth, async (user) => {
			if (user) {
				try {
					const dbUser = await getUserById(user.uid);

					if (dbUser) {
						if (dbUser.isAdmin) {
							setIsLoggedIn(true);
						} else {
							handleSignOut();
							// toast({
							//   title: "Error",
							//   description: "Please use an admin account to log in",
							//   status: "error",
							//   duration: 4000,
							//   isClosable: true,
							//   position: "top-right",
							// });
						}
					} else {
						setIsLoggedIn(false);
					}

					setLoading(false);
				} catch (err) {
					console.log(
						'🚀 ~ file: App.js ~ line 48 ~ onAuthStateChanged ~ err',
						err
					);
					setLoading(false);
					setIsLoggedIn(false);
					toast({
						title: 'Error',
						description: 'Something went wrong. Please try again',
						status: 'error',
						duration: 4000,
						isClosable: true,
						position: 'top-right',
					});
				}
			} else {
				// toast({
				//   title: "Error",
				//   description: "Something went wrong. Please try again",
				//   status: "error",
				//   duration: 4000,
				//   isClosable: true,
				//   position: "top-right",
				// });

				setIsLoggedIn(false);
				setLoading(false);
			}
		});
	}, []);

	if (loading) {
		return <Splash />;
	}

	return (
		<HashRouter>
			{isLoggedIn ? (
				<Switch>
					<Route
						path={`/admin`}
						component={AdminLayout}
					/>
					<Redirect
						from='/'
						to='/admin'
					/>
				</Switch>
			) : (
				<Switch>
					<Route
						path={`/auth`}
						component={AuthLayout}
					/>
					<Redirect
						from='/'
						to='/auth'
					/>
				</Switch>
			)}
		</HashRouter>
	);
}

export default App;
