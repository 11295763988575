import {
	collection,
	doc,
	getDocs,
	query,
	setDoc,
	where,
} from 'firebase/firestore';
import {
	getDownloadURL,
	getMetadata,
	listAll,
	ref,
} from 'firebase/storage';
import { setInitiatedOffers } from 'store/reducers/offerSlice';
import { store } from 'store/store';
import { db, storage } from 'utils/firebase';
import {
	handleGetUsers,
	handleGetAgents,
	handleGetOffers,
	handleGetApplicationResponses,
	handleGetHomebuyerApplicationResponses,
	handleGetCoapplicantApplicationResponses,
	handleGetAgentApplicationResponses,
	handleGetAgentInvites,
} from './data.service';

const getAllInitiatedOffers = async () => {
	console.log('Running getAllInitiatedOffers()');
	try {
		let offers = [];

		const offersRef = collection(db, 'offers');
		const docsSnap = await getDocs(offersRef);

		docsSnap.forEach(async (doc) => {
			const data = doc.data();

			if (!data.isDeleted) {
				offers.push({
					...data,
					uid: doc.id,
				});
			}
		});
		offers.sort(
			(a, b) => b.timeSubmitted.toDate() - a.timeSubmitted.toDate()
		);

		store.dispatch(setInitiatedOffers(offers));

		return offers;
	} catch (err) {
		console.log('Error getAllInitiatedOffers(): ', err);
		return [];
	}
};

const getAllInitiatedOffers_Optimized = async () => {
	console.log('Running getAllInitiatedOffers_Optimized()');
	try {
		let { offers: offersRaw } = store.getState()?.data;

		let offers = [];
		for (let i = 0; i < offersRaw.length; i++) {
			const data = offersRaw[i];

			if (!data.isDeleted) {
				offers.push({
					...data,
					uid: data.id,
				});
			}
		}

		offers.sort(
			(a, b) => b.timeSubmitted.toDate() - a.timeSubmitted.toDate()
		);

		store.dispatch(setInitiatedOffers(offers));

		return offers;
	} catch (err) {
		console.log('Error getAllInitiatedOffers_Optimized(): ', err);
		return [];
	}
};

const changeOfferStatusToDeleted = async (offerId) => {
	console.log('Running changeOfferStatusToDeleted()');
	try {
		const docRef = doc(db, 'offers', offerId);
		await setDoc(
			docRef,
			{
				isDeleted: true,
			},
			{
				merge: true,
			}
		);

		await handleGetOffers();
	} catch (err) {
		console.log('Error changeOfferStatusToDeleted(): ', err);
		return null;
	}
};

const getHomebuyerOffers = async (clientId, callback) => {
	console.log('Running getHomebuyerOffers()');
	try {
		const offersRef = collection(db, 'offers');
		const offerQuery = query(
			offersRef,
			where('clientId', '==', clientId)
		);
		const docsSnap = await getDocs(offerQuery);

		const offersPromise = docsSnap.docs.map(async (doc) => {
			const data = doc.data();

			if (!data.isDeleted) {
				const { rentalDocuments, saleDocuments } = await getOfferDocs(
					data?.agentId,
					doc.id
				);

				return {
					...data,
					uid: doc.id,
					rentalDocuments,
					saleDocuments,
				};
			}
		});
		const offers = await Promise.all(offersPromise);

		callback && callback();

		return offers.filter(Boolean);
	} catch (err) {
		console.log('Error getHomebuyerOffers(): ', err);
		return [];
	}
};

const getHomebuyerOffers_Optimized = async (clientId, callback) => {
	console.log('Running getHomebuyerOffers_Optimized()');
	try {
		let { offers: offersRaw } = store.getState()?.data;

		let offers = [];
		for (let i = 0; i < offersRaw.length; i++) {
			const data = offersRaw[i];

			if (clientId === data.clientId && !data.isDeleted) {
				const { rentalDocuments, saleDocuments } = await getOfferDocs(
					data?.agentId,
					data?.id
				);

				offers.push({
					...data,
					uid: data.id,
					rentalDocuments,
					saleDocuments,
				});
			}
		}

		callback && callback();

		return offers.filter(Boolean);
	} catch (err) {
		console.log('Error getHomebuyerOffers_Optimized(): ', err);
		return [];
	}
};

const getOfferDocs = async (agentId, offerId) => {
	console.log('Running getOfferDocs()');
	try {
		const verifyRef = ref(
			storage,
			`offer_initiation_files/${agentId}/${offerId}/sales`
		);
		const saleRef = ref(
			storage,
			`offer_initiation_files/${agentId}/${offerId}/sales`
		);

		const [incomeVerificationRes, saleDocRes] = await Promise.all([
			listAll(verifyRef),
			listAll(saleRef),
		]);

		const rentalDocumentsPromise = incomeVerificationRes.items.map(
			async (item) => {
				const metadata = await getMetadata(item);
				console.log(
					'🚀 ~ file: offers.service.js:97 ~ metadata:',
					metadata
				);
				const url = await getDownloadURL(item);
				return {
					url,
					type: metadata.contentType,
					name: metadata.name,
					path: `offer_initiation/${agentId}/${offerId}/rental/${metadata.name}`,
				};
			}
		);
		const rentalDocuments = await Promise.all(rentalDocumentsPromise);

		const saleDocumentsPromise = saleDocRes.items.map(
			async (item) => {
				const metadata = await getMetadata(item);
				const url = await getDownloadURL(item);
				return {
					url,
					type: metadata.contentType,
					name: `${metadata.name}`,
					path: `offer_initiation/${agentId}/${offerId}/sales/${metadata.name}`,
				};
			}
		);
		const saleDocuments = await Promise.all(saleDocumentsPromise);

		return { rentalDocuments, saleDocuments };
	} catch (err) {
		console.log('Error getOfferDocs(): ', err);
		const rentalDocuments = [];
		const saleDocuments = [];
		return { rentalDocuments, saleDocuments };
	}
};

const changeOfferStatus = async (offerId, status) => {
	console.log('Running changeOfferStatus()');
	try {
		const docRef = doc(db, 'offers', offerId);
		await setDoc(
			docRef,
			{
				status,
			},
			{
				merge: true,
			}
		);

		await handleGetOffers();
	} catch (err) {
		console.log('Error changeOfferStatus(): ', err);
		return null;
	}
};

export {
	changeOfferStatus,
	changeOfferStatusToDeleted,
	getAllInitiatedOffers,
	getHomebuyerOffers,
	getAllInitiatedOffers_Optimized,
	getHomebuyerOffers_Optimized,
};
