import { useEffect, useRef, useState } from 'react';

import {
	Box,
	Button,
	ChakraProvider,
	Flex,
	Input,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Text,
	useDisclosure,
	useOutsideClick,
} from '@chakra-ui/react';
import {
	Calendar,
	CalendarControls,
	CalendarDays,
	CalendarMonth,
	CalendarMonthName,
	CalendarMonths,
	CalendarNextButton,
	CalendarPrevButton,
	CalendarWeek,
} from '@uselessdev/datepicker';
import { format, isAfter, isBefore, isValid } from 'date-fns';
import { PickerTheme } from './theme';

export const DatePicker = ({ closeAction, clearAction }) => {
	const [dates, setDates] = useState({});
	const [values, setValues] = useState({
		start: '',
		end: '',
	});

	const { isOpen, onOpen, onClose } = useDisclosure();

	const initialRef = useRef(null);
	const calendarRef = useRef(null);
	const startInputRef = useRef(null);
	const endInputRef = useRef(null);

	const MONTHS = 2;

	const handleClose = () => {
		closeAction && closeAction(values);
		onClose();
	};

	const handleSelectDate = (dates) => {
		setDates(dates);

		setValues({
			start: isValid(dates.start)
				? format(dates.start, 'MM/dd/yyyy')
				: '',
			end: isValid(dates.end) ? format(dates.end, 'MM/dd/yyyy') : '',
		});

		// if (dates.end) {
		//   handleClose();
		// }
	};

	const match = (value) => value.match(/(\d{2})\/(\d{2})\/(\d{4})/);

	const handleInputChange = ({ target }) => {
		setValues({
			...values,
			[target.name]: target.value,
		});

		if (
			target.name === 'start' &&
			match(target.value) &&
			endInputRef.current
		) {
			endInputRef.current.focus();
		}
	};

	useOutsideClick({
		ref: calendarRef,
		handler: handleClose,
		enabled: isOpen,
	});

	useEffect(() => {
		if (match(values.start)) {
			const startDate = new Date(values.start);
			const isValidStartDate = isValid(startDate);
			const isAfterEndDate =
				dates.end && isAfter(startDate, dates.end);

			if (isValidStartDate && isAfterEndDate) {
				setValues({ ...values, end: '' });
				return setDates({ end: undefined, start: startDate });
			}

			return setDates({ ...dates, start: startDate });
		}
	}, [values.start]);

	useEffect(() => {
		if (match(values.end)) {
			const endDate = new Date(values.end);
			const isValidEndDate = isValid(endDate);
			const isBeforeStartDate =
				dates.start && isBefore(endDate, dates.start);

			if (isValidEndDate && isBeforeStartDate) {
				setValues({ ...values, start: '' });

				startInputRef.current?.focus();

				return setDates({ start: undefined, end: endDate });
			}

			// handleClose();

			return setDates({ ...dates, end: endDate });
		}
	}, [values.end]);

	return (
		<ChakraProvider theme={PickerTheme}>
			<Box>
				<Popover
					placement='auto-start'
					isOpen={isOpen}
					onClose={handleClose}
					initialFocusRef={initialRef}
					isLazy>
					<PopoverTrigger>
						<Flex alignItems={'center'}>
							<Flex
								w='400px'
								borderWidth={1}
								rounded='md'
								p={2}
								onClick={onOpen}
								ref={initialRef}
								alignItems='center'
								// background="red"
							>
								<Input
									variant='unstyled'
									name='start'
									placeholder='mm/dd/yyyy'
									value={values.start}
									onChange={handleInputChange}
									ref={startInputRef}
									textAlign={'center'}
								/>
								<Text>-</Text>
								<Input
									variant='unstyled'
									name='end'
									placeholder='mm/dd/yyyy'
									value={values.end}
									onChange={handleInputChange}
									ref={endInputRef}
									textAlign={'center'}
								/>
							</Flex>

							<Button
								disabled={!values.start || !values.end}
								ml={2}
								onClick={handleClose}
								colorScheme='blue'>
								Filter
							</Button>

							<Button
								ml={2}
								onClick={() => {
									setValues({
										start: '',
										end: '',
									});
									setDates({});
									clearAction && clearAction();
								}}
								colorScheme='red'>
								Clear Filter
							</Button>
						</Flex>
					</PopoverTrigger>

					<PopoverContent
						p={0}
						w='min-content'
						border='none'
						outline='none'
						_focus={{ boxShadow: 'none' }}
						ref={calendarRef}>
						<Calendar
							value={dates}
							onSelectDate={handleSelectDate}
							months={MONTHS}
							disableFutureDates>
							<PopoverBody p={0}>
								<CalendarControls>
									<CalendarPrevButton />
									<CalendarNextButton />
								</CalendarControls>

								<CalendarMonths>
									{[...Array(MONTHS).keys()].map((m) => (
										<CalendarMonth
											key={m}
											month={m}>
											<CalendarMonthName />
											<CalendarWeek />
											<CalendarDays />
										</CalendarMonth>
									))}
								</CalendarMonths>
							</PopoverBody>
						</Calendar>
					</PopoverContent>
				</Popover>
			</Box>
		</ChakraProvider>
	);
};
