import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import { useAuth } from "contexts/AuthContext";
import { Field, Formik } from "formik";
import { FcGoogle } from "react-icons/fc";
import { logInSchema } from "validations/LogInScheme";

const SignIn = () => {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";

  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const toast = useToast();
  const { handleGoogleLogIn, handleLogIn, authLoading } = useAuth();

  return (
    <DefaultAuth
      illustrationBackground={"https://i.imgur.com/JLJ3ZIZ.jpg"}
      image={"https://i.imgur.com/JLJ3ZIZ.jpg"}
    >
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign In
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Button
            fontSize="sm"
            me="0px"
            mb="26px"
            py="15px"
            h="50px"
            borderRadius="16px"
            bg={googleBg}
            color={googleText}
            fontWeight="500"
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}
            onClick={async () => {
              const { error } = await handleGoogleLogIn();
              if (error) {
                toast({
                  title: "Error on Google Log In",
                  description: error,
                  status: "error",
                  duration: 4000,
                  isClosable: true,
                  position: "top-right",
                });
              }
            }}
          >
            <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
            Sign in with Google
          </Button>

          <Flex align="center" mb="25px">
            <HSeparator />
            <Text color="gray.400" mx="14px">
              or
            </Text>
            <HSeparator />
          </Flex>

          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={logInSchema}
            onSubmit={async (values) => {
              const { error } = await handleLogIn(
                values.email,
                values.password
              );
              if (error) {
                toast({
                  title: "Error on Log In",
                  description: error,
                  status: "error",
                  duration: 4000,
                  isClosable: true,
                  position: "top-right",
                });
              }
            }}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <Box>
                  <FormControl
                    isInvalid={formik.errors.email && formik.touched.email}
                    mb="3"
                  >
                    <FormLabel>Email address</FormLabel>
                    <Field
                      as={Input}
                      id="email"
                      name="email"
                      type="email"
                      disabled={authLoading}
                    />
                    <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={
                      formik.errors.password && formik.touched.password
                    }
                    mb="3"
                  >
                    <FormLabel>Password</FormLabel>
                    <Field
                      as={Input}
                      id="password"
                      name="password"
                      type="password"
                      disabled={authLoading}
                    />

                    <FormErrorMessage>
                      {formik.errors.password}
                    </FormErrorMessage>
                  </FormControl>

                  <Stack spacing={10} pb="5">
                    <Stack
                      direction={{ base: "column", sm: "row" }}
                      align={"start"}
                      justify={"space-between"}
                    >
                      <Checkbox>Remember me</Checkbox>‚{" "}
                    </Stack>
                    <Button type="submit" colorScheme="blue">
                      {formik.isSubmitting || authLoading ? (
                        <Spinner />
                      ) : (
                        "Log In"
                      )}
                    </Button>
                  </Stack>
                </Box>
              </form>
            )}
          </Formik>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
};

export default SignIn;
