import { DeleteIcon } from '@chakra-ui/icons';
import { Button, Text } from '@chakra-ui/react';
import CustomTable from 'components/customTable';
import moment from 'moment-timezone';
import React from 'react';
import ApplicationDetails from './ApplicationDetails';

const UsersList = (props) => {
	const {
		data,
		setSelectedUser,
		selectedUser,
		loading,
		getStorageData,
		applicationData,
		storageData,
		setStorageData,
		setApplicationData,
		handleDelete,
		userLoading,
	} = props;

	const closeModal = () => {
		setSelectedUser(null);
		setStorageData(null);
	};

	const columns = React.useMemo(
		() => [
			{
				header: () => <span>#</span>,
				accessorKey: 'serialNo',
				enableSorting: false,
				cell: (row) => {
					return <div>{row?.row?.index + 1}</div>;
				},
			},
			{
				accessorKey: 'displayId',
				header: () => <span>Id</span>,
				footer: (props) => props.column.id,
			},
			{
				accessorKey: 'firstName',
				header: () => <span>First Name</span>,
				footer: (props) => props.column.id,
			},
			{
				accessorKey: 'lastName',
				header: () => <span>Last Name</span>,
				footer: (props) => props.column.id,
			},
			{
				accessorKey: 'email',
				header: () => <span>Email</span>,
				footer: (props) => props.column.id,
			},
			{
				accessorKey: 'phoneNumber',
				header: () => <span>Phone Number</span>,
				footer: (props) => props.column.id,
				sortType: 'number',
				cell: (row) => {
					let value = row.getValue();
					return <Text>{value?.toLocaleString()}</Text>;
				},
			},

			{
				accessorKey: 'createdAt',
				header: () => <span>Created At</span>,
				footer: (props) => props.column.id,
				sortType: 'datetime',
				cell: (row) => {
					let value = row.getValue();
					return (
						<Text>
							{value
								? moment(value)
										.tz('America/New_York')
										.format('DD MMM YYYY,  hh:mm a')
								: ''}
						</Text>
					);
				},
			},

			{
				accessorKey: 'submissionDate',
				header: () => <span>Submission Date</span>,
				footer: (props) => props.column.id,
				sortType: 'datetime',
				cell: (row) => {
					let value = row.getValue();
					return (
						<Text>
							{value
								? moment(value)
										.tz('America/New_York')
										.format('DD MMM YYYY,  hh:mm a')
								: ''}
						</Text>
					);
				},
			},

			{
				accessorKey: 'actions',
				header: () => <span>Actions</span>,
				footer: (props) => props.column.id,
				cell: (row) => {
					return (
						<Button
							width={'100%'}
							maxH={'25px'}
							background='transparent'>
							<DeleteIcon color='red.400' />
						</Button>
					);
				},
			},
		],
		[]
	);

	return (
		<>
			<CustomTable
				data={data}
				columns={columns}
				onRowPress={(data) => {
					getStorageData(data.id);
					setSelectedUser(data);
					setApplicationData(data.application);
				}}
				loading={userLoading}
				onActionPress={(data) => {
					const name =
						data?.legalName?.firstName +
						' ' +
						data?.legalName?.lastName;
					handleDelete(data.id, name);
				}}
			/>

			<ApplicationDetails
				closeModal={closeModal}
				selectedUser={selectedUser}
				isOpen={Boolean(selectedUser)}
				loading={loading}
				applicationData={applicationData}
				storageData={storageData}
			/>
		</>
	);
};

export default UsersList;
